import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-success-modal',
  templateUrl: './order-success-modal.component.html',
  styleUrls: ['./order-success-modal.component.css']
})
export class OrderSuccessModalComponent {
  email: string;
  orderId: string;
  companyName: string;
  tableNumber: string;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<OrderSuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, email: string, companyName: string, tableNumber: string }
  ) {
    this.orderId = data.orderId;
    this.email = data.email;
    this.companyName = data.companyName;
    this.tableNumber = data.tableNumber;

  }

  closeDialog() {
    this.router.navigate(['/restaurants/', this.companyName, this.tableNumber]);
    this.dialogRef.close();
  }
}
