import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestaurantService } from '../Services/restaurantService.service';
import { IOrder, PaymentStatusEnum, OrderStatusEnum, PaymentTypeEnum } from 'src/app/interfaces/IOrder';
import { IOrderDetails } from 'src/app/interfaces/IOrderDetails';
import { Router, ActivatedRoute } from '@angular/router';
import { FailSuccessModal } from '../fail-success-modal/fail-success-modal.component';
import { IOrderClient } from '../interfaces/IOrderClient';
import { TranslateService } from '@ngx-translate/core';
import { OrderSuccessModalComponent } from '../order-success-modal/order-success-modal.component';
import { UserService } from '../Services/user.service';
import { getUser } from '../interfaces/IUsers';
import { OrderErrorModalComponent } from '../order-error-modal/order-error-modal.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class PaymentModalComponent implements OnInit {
  @ViewChild('paymentFormView') paymentFormView: ElementRef;
  PortalUrl: string;
  Clientid: string;
  Storetype: string;
  Trantype: string;
  Instalment: string;
  amount: string;
  Currency: string;
  Oid: string;
  OkUrl: string;
  FailUrl: string;
  CallbackUrl: string;
  Lang: string;
  Encoding: string;
  Refreshtime: string;
  Rnd: string;
  Hash: string;
  totalPayment = 0;
  paymentWithCard: boolean = false;
  paymentForm = this.fb.group({
    paymentMethod: [0],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    address: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.required],
    services: ['']
  });
  lines = [];
  paymentButton: boolean = false;
  paymentFormData: any;
  isLogged:boolean = false;
  userId: number = parseInt(localStorage.getItem("userId"), 10);
  email: any;

  constructor(
    private router: Router,
    private menuService: RestaurantService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.setClientInfo();
    this.totalPayment = this.data.total;
    this.lines.length = 21;
    this.isLogged = !!localStorage.getItem("token");
    this.getUser();
  }

  setClientInfo() {
    const clientValues = JSON.parse(localStorage.getItem("clientValues"));
    this.paymentForm.setValue({
      paymentMethod: clientValues?.paymentMethod || PaymentTypeEnum.Card,
      firstName: clientValues?.firstName || "",
      lastName: clientValues?.lastName || "",
      address: clientValues?.address || "",
      email: clientValues?.email || "",
      phone: clientValues?.phone || "",
      services: clientValues?.services || ""
    })
  }

  inputChange(event) {
    localStorage.setItem('clientValues', JSON.stringify(this.paymentForm.value));
  }
  // In your component class
  selectPaymentMethod(value: number) {
    this.paymentForm.get('paymentMethod').setValue(value);
    this.inputChange(value);
  }
  payment() {
    if(this.paymentForm.valid || this.isLogged){
      let companyID = Number(localStorage.getItem('companyId'));
      let products = this.data.products;
      const orderDetails: IOrderDetails[] = products.map((product) => {

        const selectedOption = product.options.find((option) => {
          return option.id === Number(product.productDetailID);
        });

        const selectedSellingPriceWithVat = selectedOption.sellingPriceWithVat;
        const selectedSellingPriceNoVat =  selectedOption.sellingPriceNoVat ;
        const productDetailName = selectedOption.productName;
        const vatID = selectedOption.vatId;

        const total = parseInt(product.quantity) * selectedSellingPriceWithVat;
        const orderDetail: IOrderDetails = {
          productID: parseInt(product.id),
          qt: parseInt(product.quantity),
          sellingPriceWithVat: selectedSellingPriceWithVat,
          sellingPriceNoVat: selectedSellingPriceNoVat,
          productName: productDetailName,
          total: total,
          vatID: vatID,
          comment: product.comment,
          productDetailID: parseInt(product.productDetailID),
        };
        
        return orderDetail;
      });
      
      const order: IOrder = {
        companyID: companyID,
        dateOrder: new Date(),
        paymentStatus: PaymentStatusEnum.Unpaid,
        orderStatus: OrderStatusEnum.Processing,
        total: parseInt(this.data.total),
        orderDetails: orderDetails,
        isDelivery: this.data.deliveryOrPickup,
        paymentType: this.paymentForm.value.paymentMethod,
        ...(this.data.tableNumber !== 0 && { tableNumber: this.data.tableNumber })
        
      };

      let orderClient: IOrderClient | null = null;
      if (!this.isLogged) {
        orderClient = {
          Name: this.paymentForm.value.firstName,
          LastName: this.paymentForm.value.lastName,
          Email: this.paymentForm.value.email,
          Phone: this.paymentForm.value.phone,
          Address: this.paymentForm.value.address,
        };
      }

      if (this.isLogged) {
        const userId = localStorage.getItem("userId");
        if (userId) {
          order.clientConomeID = Number(userId);
        }
      }else{
        order.orderClient = orderClient;
      }

      this.createOrder(order);
    }else{
      this.paymentButton = true;
    }
  }
  
  createOrder(order) {
    this.menuService.createOrder(order, this.email || this.paymentForm.value.email).subscribe(async (res: any) => {
      let orderId = res.orderId;
      if (res.status) {
        if (this.paymentForm.value.paymentMethod == 1) {
          this.paymentWithCard = true;
          const url = window.location.href;
          const baseUrl = url.replace('/cart', '');
          this.menuService.checkOut(this.data.total, res.orderId, baseUrl).subscribe((res: any) => {
            this.PortalUrl = res.portalUrl;
            this.Clientid = res.clientid;
            this.Storetype = res.storetype;
            this.Trantype = res.trantype;
            this.Instalment = res.instalment;
            this.amount = res.amount.toFixed(2);
            this.Currency = res.currency;
            this.Oid = res.oid;
            this.OkUrl = res.okUrl;
            this.FailUrl = res.failUrl;
            this.CallbackUrl = res.callbackUrl;
            this.Lang = res.lang;
            this.Encoding = res.encoding;
            this.Refreshtime = res.refreshtime;
            this.Rnd = res.rnd;
            this.Hash = res.hash;
            requestAnimationFrame(() => {
              const paymentFormView = this.paymentFormView.nativeElement;
              this.submitForm(paymentFormView);
            });
          });
        } else {
          localStorage.removeItem('products');
          localStorage.removeItem('clientValues');
          this.dialogRef.close();
          this.dialog.open(OrderSuccessModalComponent, {
            data: { orderId: orderId, email: this.email || this.paymentForm.value.email, companyName: this.data.companyName, tableNumber: this.data.tableNumber },
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            panelClass: 'full-screen-dialog'
          });
        }
        this.sendOrderEmail(orderId, res.userId);
      } else {
        this.dialog.open(OrderErrorModalComponent, {
          data: { orderId: orderId, email: this.email },
          width: '100vw',
          height: '100vh',
          maxWidth: '100vw',
          panelClass: 'full-screen-dialog'
        });
      }
    }, (error: HttpErrorResponse) => {
      const orderErrorModalRef = this.dialog.open(OrderErrorModalComponent, {
        data: {  },
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        panelClass: 'full-screen-dialog'
      });

      orderErrorModalRef.afterClosed().subscribe(() => {
        this.dialogRef.close();
      });
    })
  }
  
  sendOrderEmail(orderId: number, userId: number) {
    this.menuService.sendOrderEmail(orderId, userId).subscribe({
      next: (res) => {
      },
      error: (error) => {
        console.error('Error retrieving order details:', error);
      }
    });
  }

  getUser(): void {
    this.userService.getUser(this.userId).subscribe(
      (data: getUser) => {
        const user = data[0];
        this.email = user.email;
      },
      (error) => {
        console.error('Error fetching user data', error);
      }
    );
  }

  submitForm(paymentForm: HTMLFormElement) {
    paymentForm.submit();
  }
}
