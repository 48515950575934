import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fail-success-modal',
  templateUrl: './fail-success-modal.component.html',
  styleUrls: ['./fail-success-modal.component.css']
})

export class FailSuccessModal {
  modalContent: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any,
  public dialogRef: MatDialogRef<FailSuccessModal>) {
    this.modalContent = data.modalContent;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}