<app-header></app-header>


<div class="user-page">
  <form class="user-form" [formGroup]="signUpForm" (ngSubmit)="register(signUpForm.value)">

    <!-- Name -->
    <input matInput formControlName="Name" placeholder="{{'SIGNUP.NAME' | translate}}"
      (input)="makeFirstLetterUpperCase('Name',$event)" />
    <span *ngIf="signUpForm.controls['Name'].invalid && (signUpForm.controls['Name'].touched || isFormSubmitted)"
      class="text-danger">{{'SIGNUP.REQUIRED_NAME' | translate}}</span>
    <!-- Surname   -->
    <input matInput formControlName="Surname" placeholder="{{'SIGNUP.SURNAME' | translate}}"
      (input)="makeFirstLetterUpperCase('Surname',$event)" />
    <span *ngIf="signUpForm.controls['Surname'].invalid && (signUpForm.controls['Surname'].touched || isFormSubmitted)"
      class="text-danger">{{'SIGNUP.REQUIRED_SURNAME' | translate}}</span>
    <!-- Email -->
    <input matInput formControlName="Email" placeholder="{{'SIGNUP.EMAIL' | translate}}" />
    <span *ngIf="signUpForm.controls['Email'].errors?.email" class="text-danger">{{'SIGNUP.NOT_VALID_EMAIL' |
      translate}}</span>
    <!-- Password -->
    <div class="eye-icon-container">
      <div style="position: relative;">
        <input matInput [type]="viewPassword ? 'text' : 'password'" formControlName="Password"
          placeholder="{{'SIGNUP.PASSWORD' | translate}}" />
        <img class="eye-icon" src="../../assets/icons/eye.ico" height="25" *ngIf="!viewPassword" (click)="passwordShowHide()" />
        <img class="eye-icon" src="../../assets/icons/eye-off.ico" height="25" *ngIf="viewPassword" (click)="passwordShowHide()" />
      </div>
    </div>
    
    <div
      *ngIf="signUpForm.controls['Password'].invalid && (signUpForm.controls['Password'].touched || isFormSubmitted)">
      <span *ngIf="signUpForm.controls['Password'].errors?.required" class="text-danger">{{'SIGNUP.REQUIRED_PASSWORD' |
        translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.minlength" class="text-danger">{{'SIGNUP.ERROR_MIN_LENGTH' |
        translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresDigit" class="text-danger">{{'SIGNUP.REQUIRED_NUMBER'
        | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresSpecialChars"
        class="text-danger">{{'SIGNUP.REQUIRED_SPECIAL_CHARACTER' | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresUppercase"
        class="text-danger">{{'SIGNUP.REQUIRED_UPPERCASE' | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresLowercase"
        class="text-danger">{{'SIGNUP.REQUIRED_LOWERCASE' | translate}}<br /></span>
    </div>
    <!-- Confirm password   -->
    <input matInput [type]="viewPassword ? 'text' : 'password'" formControlName="ConfirmPassword"
      placeholder="{{'SIGNUP.PASSWORD_CONFIRM' | translate}}" />
    <span
      *ngIf="(signUpForm.controls['ConfirmPassword'].touched || isFormSubmitted) && signUpForm.controls['Password'].value !== signUpForm.controls['ConfirmPassword'].value"
      class="text-danger">{{'SIGNUP.NOT_MATCH_PASSWORDS' | translate}}</span>
    <!-- Address  -->
    <input matInput formControlName="Address" placeholder="{{'SIGNUP.ADDRESS' | translate}}" />
    <!-- Place   -->
    <mat-select formControlName="PlaceId" class="dropdown" placeholder="{{'SIGNUP.PLACE' | translate}}">
      <mat-option *ngFor="let place of places" [value]="place.id">
        {{selectedLanguage === 'al' ? place.citySQ : place.cityMK}}
      </mat-option>
    </mat-select>
    <!-- AddressNumber     -->
    <input matInput formControlName="PhoneNumber" placeholder="{{'PAYMENT_MODAL.PHONE_NUMBER' | translate}}" />
    <input matInput formControlName="AddressNumber" placeholder="{{'SIGNUP.ADDRESS_NUMBER' | translate}}" />
    <span
      *ngIf="signUpForm.controls['AddressNumber'].invalid && (signUpForm.controls['AddressNumber'].touched || isFormSubmitted)"
      class="text-danger">{{'SIGNUP.REQUIRED_ADDRESS_NUMBER' | translate}}</span>
    <!-- Register -->
    <button mat-raised-button class="user-signup-btn" appearance="fill">{{'SIGNUP.REGISTER' | translate}}</button>
  </form>

  <span *ngIf="registerSuccess" class="text-info h2">{{'SIGNUP.SINUP_SUCCESSFUL' | translate}}</span>
  <span *ngIf="registerFail" class="text-danger h2">{{'SIGNUP.SINUP_FAILED' | translate}}:
    {{registerFail|translate}}</span>

  <br>

  <a [routerLink]="['/signin']" routerLinkActive="router-link-active">{{'SIGNUP.ALREADY_HAVE_LOGIN_CREDENTIALS' |
    translate}}</a>
</div>