

<app-header [showSearch]="false"></app-header>

<div class="user-page">
  <form class="user-form" [formGroup]="changePassword">
    <mat-form-field appearance="fill" class="user-form-field">
      <mat-label>{{ 'CHANGE_PASSWORD.NEW_PASSWORD' | translate }}</mat-label>
      <div class="input-container">
        <input matInput id="password" [type]="viewPassword ? 'text' : 'password'" placeholder="{{ 'CHANGE_PASSWORD.NEW_PASSWORD' | translate }}" formControlName="NewPassword" />
        <div class="eye-icon-container">
          <img class="eye-icon" src="../../assets/icons/eye.ico" height="25" *ngIf="!viewPassword" (click)="passwordShowHide()" />
          <img class="eye-icon" src="../../assets/icons/eye-off.ico" height="25" *ngIf="viewPassword" (click)="passwordShowHide()" />
        </div>
      </div>
    </mat-form-field>
    <div
      *ngIf="changePassword.controls['NewPassword'].invalid && (changePassword.controls['NewPassword'].touched || isFormSubmitted)">
      <span *ngIf="changePassword.controls['NewPassword'].errors?.required" class="text-danger">{{'SIGNUP.REQUIRED_PASSWORD' |
        translate}}<br /></span>
      <span *ngIf="changePassword.controls['NewPassword'].errors?.minlength" class="text-danger">{{'SIGNUP.ERROR_MIN_LENGTH' |
        translate}}<br /></span>
      <span *ngIf="changePassword.controls['NewPassword'].errors?.requiresDigit" class="text-danger">{{'SIGNUP.REQUIRED_NUMBER'
        | translate}}<br /></span>
      <span *ngIf="changePassword.controls['NewPassword'].errors?.requiresSpecialChars"
        class="text-danger">{{'SIGNUP.REQUIRED_SPECIAL_CHARACTER' | translate}}<br /></span>
      <span *ngIf="changePassword.controls['NewPassword'].errors?.requiresUppercase"
        class="text-danger">{{'SIGNUP.REQUIRED_UPPERCASE' | translate}}<br /></span>
      <span *ngIf="changePassword.controls['NewPassword'].errors?.requiresLowercase"
        class="text-danger">{{'SIGNUP.REQUIRED_LOWERCASE' | translate}}<br /></span>
    </div>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD' | translate}}</mat-label>
      <input [type]="viewPassword ? 'text' : 'password'" matInput placeholder="{{'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD' | translate}}" value="" formControlName="ConfirmNewPassword">
    </mat-form-field>
    <div
    *ngIf="(changePassword.controls['ConfirmNewPassword'].touched || isFormSubmitted) && changePassword.controls['NewPassword'].value !== changePassword.controls['ConfirmNewPassword'].value"
    class="text-danger">{{'SIGNUP.NOT_MATCH_PASSWORDS' | translate}}</div>
    <div [ngClass]="labelError !== ''? 'visible':'invisible'" class="text-danger">
      {{labelError | translate}}
    </div>
    <div [ngClass]="labelError !== ''? 'visible':'invisible'" class="text-info">
      {{labelInfo | translate}}
    </div>
  </form>
  <br>
  <button mat-raised-button class="change-password-btn" appearance="fill" (click)="changepassword(changePassword.value)">{{'CHANGE_PASSWORD.SUBMIT' | translate}}</button>
  <br>
  <br>
  <a [routerLink]="['/user-profile']" >{{'CHANGE_PASSWORD.BACK_TO_USER' | translate}}</a>

</div>