import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RestaurantService } from '../Services/restaurantService.service';
import { Category } from '../categoryObj';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { MatDialog } from '@angular/material/dialog';
import { FailSuccessModal } from '../fail-success-modal/fail-success-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { SwiperComponent } from 'swiper/angular';
import { NavigationService } from '../Services/navigation.service';
import { filter, Subscription } from 'rxjs';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-client-component',
  templateUrl: './client-component.component.html',
  styleUrls: ['./client-component.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ClientComponentComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  img_path = environment.IMG_PATH;
  companyName: string;
  tableNumber: number = 0;
  defaultImage = environment.DEFAULT_IMAGE

  constructor(
    private menuService: RestaurantService,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {
    this.companyName = this.activatedRoute.snapshot.paramMap.get('companyName');
    this.tableNumber = Number(this.activatedRoute.snapshot.paramMap.get('tableNumber'));
    this.nameLang = localStorage.getItem('selectedLanguage');
    if (this.nameLang === 'al') {
      this.name = 'nameSQ'
    } else if (this.nameLang === 'mk') {
      this.name = 'nameMK'
    } else {
      this.name = 'nameSQ'
    }
  }
  images: any;
  name = 'nameSQ';
  imgLogo = '';
  categories = []
  products = [];
  productsByCategory = [];
  filteredProducts = [];
  filteredProductsByCategory = [];
  cartProducts = [];
  cartProductsNr = 0;
  updatedQuantity = 1;
  totalPrice = 0;
  categorySelected: string = 'all';
  id: any;
  company: any;
  nameLang: string;
  searchText = '';
  searchValue = '';
  companyLogo: string;
  categoryId: number = null;
  productQuanity = {
    id: '',
    quantity: 1
  };
  sizeName: string;
  priceSelected: number;
  productDetailID: number;
  buyingPriceWithNoVat: number;
  vatID: number;
  productDetails = [
    { value: 'sm', name: 'small', price: 180, "checked": false },
    { value: 'md', name: 'medium', price: 240, "checked": true },
    { value: 'lg', name: 'large', price: 320, "checked": false }
  ]
  // Count of total products fetched
totalProductsFetched: number = 0;

// Limit for the number of products to fetch
productLimit: number = 20; // or any number you prefer

// Number of products to fetch per API call (optional, define as needed)
productsPerPage: number = 20; // Set the number of products you want per page

totalProductsFetchedByCategory: number = 0
productLimitByCategory: number = 20;
productsPerPageByCategory: number = 20;
previousUrl: string | null;
currentUrl: string | null;
private popStateListener: EventListener;

onImageError(event: Event) {
  const defaultImage = this.defaultImage; 
  (event.target as HTMLImageElement).src = defaultImage;
}

directAccess: any;
ngOnInit(): void {

  this.activatedRoute.queryParamMap.subscribe(queryParams => {
    this.directAccess = queryParams.get('directAccess');
    const clientPageAccessKey = 'client_page_direct_access';

    if(this.directAccess === "false"){
      localStorage.setItem(clientPageAccessKey, 'false');
    }
    else{
      localStorage.setItem(clientPageAccessKey, 'true');
    }
  });
  
  
  localStorage.setItem('originalUrl', this.router.url);

  
  const queryString = window.location.search;
  
  if (queryString.includes('paymentStatus=1')) {
    this.openGenericModal("Porosia u krijua me sukses!");
    localStorage.removeItem('products');
    localStorage.removeItem('clientValues');
  } else if (queryString.includes('paymentStatus=0')) {
    this.openGenericModal("Porosia nuk u krijua!");
  }

  localStorage.setItem('companyName', this.companyName);
  this.getMenu();

  

  // Add popstate listener to handle back navigation
  this.popStateListener = () => {
    // Check if the previous page was from /restaurants
    if (this.navigationService.isComingFrom('/restaurants')) {
      // If coming from restaurants, navigate back to restaurants
      this.navigationService.navigateBack('/restaurants');
    } else {
      // If not from restaurants, prevent navigation
      history.pushState(null, '', window.location.href);
    }
  };

  // Add the event listener
  window.addEventListener('popstate', this.popStateListener);

  this.cartProductsNr = JSON.parse(localStorage.getItem('products') || "[]").length;

  this.translate.onLangChange.subscribe(x => {
    this.nameLang = localStorage.getItem('selectedLanguage');
    this.name = this.nameLang === 'mk' ? 'nameMK' : 'nameSQ';
  });

  this.total();
}
private routerSubscription: Subscription;

ngOnDestroy(): void {
  // Clear the direct access flag when leaving the component
  /* const clientPageAccessKey = 'client_page_direct_access';
  console.log(clientPageAccessKey, 'test')
  localStorage.removeItem(clientPageAccessKey); */
}

  openGenericModal(content: string): void {
    const dialogRef = this.dialog.open(FailSuccessModal, {
      data: { modalContent: content }
    });
  }

  getMenu() {
    this.id = localStorage.getItem('companyId');
    if (this.id) {
      // this.companyLogo = res[0]?.image;
      this.companyLogo = localStorage.getItem('companyLogo');
      this.products = []; // Clear products array before fetching new
      // Fetch products
      this.getAllCategories(this.id);
      this.fetchProducts();
    } else {
        // Handle the case where companyId is null or empty
         const token = localStorage.getItem('companyToken');

        this.menuService.getCompanyIdByName(this.companyName)
        .subscribe((res: any) => {
          this.id = res[0]?.id;

          this.companyLogo = res[0]?.image;
          localStorage.setItem('companyId', this.id);

          this.products = []; // Clear products array before fetching new
          // Fetch products
          this.getAllCategories(this.id);
          this.fetchProducts();
    });
    }
      
  }

  getAllCategories(companyId){
    this.menuService.getAllCategories(companyId).subscribe((res: any)=>{
        this.categories = res.categories
    })
  }

  // Updated method to fetch products with Promise
  fetchProducts(): Promise<void> {
    this.categoryId = null;
    return new Promise<void>((resolve, reject) => {
      if (this.totalProductsFetched >= this.productLimit - 5) {
        return resolve(); // Stop if the limit is reached
      }

      // Define your current page and products per page
      const currentPage = Math.ceil(this.totalProductsFetched / this.productsPerPage) + 1;

      this.menuService.get_Menu(this.id, currentPage, this.productsPerPage)
        .subscribe({
          next: (res: Category[]) => {
            res.forEach(category => {
              category.products.forEach(product => {
                if (this.totalProductsFetched < this.productLimit) {
                  const prod = { ...product, quantity: 1 };
                  this.products.push(prod);
                  this.totalProductsFetched++; // Increment fetched products count
                }
              });
            });

            // Update filtered products for display
            this.filteredProducts = this.products;

            // Optionally fetch more if needed
            if (this.totalProductsFetched < this.productLimit - 5) {
              /* this.fetchProducts();  */// Fetch again if limit isn't reached
            }

            this.selectedProduct(0); // Adjust as needed for initial selection
            resolve(); // Resolve when done
          },
          error: (err) => {
            console.error('Error fetching products', err);
            reject(err); // Reject on error
          }
        });
    });
  }

    // Updated method to fetch products with Promise
    fetchProductsByCategoryId(categoryId): Promise<void> {
      var stop = false
      this.categoryId =categoryId
      // console.log('here')
      return new Promise<void>((resolve, reject) => {
        if (this.totalProductsFetchedByCategory >= this.productLimitByCategory - 5 || stop) {
          return resolve(); // Stop if the limit is reached
        }
  
        // Define your current page and products per page
        const currentPage = Math.ceil(this.totalProductsFetchedByCategory / this.productsPerPageByCategory) + 1;
  
        this.menuService.getProductsByCategory(this.id, currentPage, this.productsPerPageByCategory, categoryId)
          .subscribe({
            next: (res: any) => {
              if(res == undefined || res[0]?.products.length < this.productLimitByCategory){
                stop = true
              }
              res.forEach(category => {
                category.products.forEach(product => {
                  if (this.totalProductsFetchedByCategory < this.productLimitByCategory) {
                    const prod = { ...product, quantity: 1 };
                    this.productsByCategory.push(prod);
                    this.totalProductsFetchedByCategory++; // Increment fetched products count
                  }
                });
              });
              // Update filtered products for display
              this.filteredProductsByCategory = this.productsByCategory;
  
              // Optionally fetch more if needed
              if (this.totalProductsFetchedByCategory < this.productLimitByCategory - 5 && !stop) {
                this.fetchProductsByCategoryId(categoryId); // Fetch again if limit isn't reached
              }
  
              this.selectedProduct(0); // Adjust as needed for initial selection
              resolve(); // Resolve when done
            },
            error: (err) => {
              console.error('Error fetching products', err);
              reject(err); // Reject on error
            }
          });
      });
    }

  albLang() {
    this.name = 'nameSQ';
    localStorage.setItem('selectedLanguage', JSON.stringify(this.name));
    localStorage.setItem('selectedLanguage', 'al');

  }
  mkdLang() {
    this.name = 'nameMK';
    localStorage.setItem('selectedLanguage', JSON.stringify(this.name));
    localStorage.setItem('selectedLanguage', 'mk');
  }

  goToCart() {
    let restaurantName = localStorage.getItem("companyName");
    
    if (this.tableNumber > 0) {
      this.router.navigate(
        ['/restaurants', restaurantName, this.tableNumber, 'cart'], 
        { queryParams: { directAccess: this.directAccess === "false" ? false : true }}
      );
    } else {
      this.router.navigate(
        ['/restaurants', restaurantName, 'cart'],
        { queryParams: { directAccess: this.directAccess === "false" ? false : true }}
      );
    }
}

  allCategories() {
    this.swiper?.swiperRef.slideTo(0,0)
    this.categorySelected = 'all';
    this.filteredProducts = this.products;
  }

  filterByCategory(categoryName, categoryId) {    
    this.fetchProductsByCategoryId(categoryId).then(() => {
      // Call detectChanges after the products are fetched
      this.swiper?.swiperRef.slideTo(0,0)
      this.productsByCategory = []
      this.totalProductsFetchedByCategory = 0
      this.categorySelected = categoryName;
      categoryName = categoryName.toLowerCase();
      this.filteredProducts = this.filteredProductsByCategory
      this.selectedProduct(0);
      this.cdr.detectChanges();
    })
    
  }

  addQuantity(product) {
    product.quantity = product.quantity + 1;
    const prod = {...product, quantity: product.quantity};
    this.productQuanity = prod;
  }

  removeQuantity(product) {
    if(product.quantity > 1) {
      product.quantity = product.quantity - 1;
      const prod = {...product, quantity: product.quantity};
      this.productQuanity = prod;
    }
  }

  onSlideChange(swiper) {
    var allCategory = true
    if(this.filteredProductsByCategory.length>0){
      allCategory = false
    }
    if(allCategory){
      const activeSlideIndex = swiper[0].activeIndex;
      this.selectedProduct(activeSlideIndex);
    
      // Check if the current slide index is near or at the product limit
      if (activeSlideIndex >= this.productLimit - 5) {
        // Increase product limit by the next 20 products
        this.productLimit += 20;
  
        // Fetch products and use .then() to handle the completion
        this.fetchProducts()
          .then(() => {
            // Call detectChanges after the products are fetched
            this.cdr.detectChanges();
          })
          .catch((err) => {
            console.error('Failed to fetch products:', err);
          });
      }
    }
    else{
      const activeSlideIndex = swiper[0].activeIndex;
      this.selectedProduct(activeSlideIndex);
    
      // Check if the current slide index is near or at the product limit
      if (activeSlideIndex >= this.productLimitByCategory - 5) {
        // Increase product limit by the next 20 products
        this.productLimitByCategory += 20;
  
        // Fetch products and use .then() to handle the completion
        // console.log('here3')

        this.fetchProductsByCategoryId(this.categoryId)
          .then(() => {
            // Call detectChanges after the products are fetched
            this.cdr.detectChanges();
          })
          .catch((err) => {
            console.error('Failed to fetch products:', err);
          });
      }
    }
    
  }

  selectedProduct(index){
    const activeProduct = this.filteredProducts[index];
    this.selectedPrice(activeProduct.options[0])
    this.cdr.detectChanges();
  }
  selectedPrice(details) {
    this.sizeName = details?.productName;
    this.priceSelected = details?.sellingPriceWithVat;
    this.productDetailID = details?.id;
    this.buyingPriceWithNoVat = details?.sellingPriceNoVat;
    this.vatID = details?.vatID;
  }

  addToCart(product) {
    this.cartProducts = JSON.parse(localStorage.getItem('products') || "[]");
    const foundProductIndex = this.cartProducts.findIndex((prod) => prod.id === product?.id && prod.productDetailID === this.productDetailID);
    if (foundProductIndex !== -1) {
      this.cartProducts[foundProductIndex].quantity += product.quantity;
    } else {
      product.vatId = this.vatID;
      this.cartProducts.push({...product, price: this.priceSelected, sizeName: this.sizeName, productDetailID: this.productDetailID, buyingPriceWithNoVat: this.buyingPriceWithNoVat });
    }
    localStorage.setItem('products', JSON.stringify(this.cartProducts));
    this.cartProductsNr = this.cartProducts.length;
    this.total();
  }

  total() {
    this.cartProducts = JSON.parse(localStorage.getItem('products') || "[]");
    this.totalPrice = this.cartProducts.reduce((sum, prod) => {
      const matchedOption = prod.options?.find(option => option.id ===  this.productDetailID);
      const priceToUse = matchedOption ? matchedOption.sellingPriceWithVat : prod.sellingPriceWithVat;
      return sum += priceToUse * prod.quantity;
    }, 0);
  
    return this.totalPrice;
  }
}