import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../Services/user.service';
import { getUser } from '../interfaces/IUsers';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {
  selectedLanguage: string;
  userId: number = parseInt(localStorage.getItem("userId"), 10);
  name:string;
  surname:string;
  email:string;
  address:string;
  token: string = localStorage.getItem('token');

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private userService:UserService
    ) { }

  ngOnInit(): void {
    if (localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    } else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.getUser(); 
  }

  getUser(): void {
    if (this.userId !== 0) {
      this.userService.getUser(this.userId).subscribe(
        (data: getUser) => {
          const user = data[0];
  
          this.name = user.name;
          this.surname = user.surname;
          this.email = user.email;
          this.address = user.address;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    } else {
      this.resetUserData();
    }
  }
  
   resetUserData(): void {
    this.name = '';
    this.surname = '';
    this.email = '';
    this.address = '';
  }

}
