<div class="menuDiv" fxLayout="row" fxLayoutWrap>
  <i class="material-icons close-icon mt-0" (click)="backClicked()">close</i>
    <h3 class="menu">{{'MENU.MENU' | translate}}</h3>
</div>

<div class="menu-container">
  <div fxLayout="row" fxLayoutWrap>
    <!-- <button class="menu-buttons" mat-raised-button [routerLink]="['/restaurants', companyName]">
      {{'MENU.MENU' | translate}}<i class="material-icons">restaurant</i>
    </button> -->
    <button class="menu-buttons" mat-raised-button [routerLink]="['/restaurants']">
      {{'MENU.MENU' | translate}}<i class="material-icons">restaurant</i>
    </button>
    <button class="menu-buttons" mat-raised-button [routerLink]="['/my-orders']">
      {{'MENU.MY_ORDERS' | translate}}<i class="material-icons">east</i>
    </button>
  </div>
  <div fxLayout="row" fxLayoutWrap>
    <button class="menu-buttons" mat-raised-button [routerLink]="['/user-profile']">
      {{'MENU.USER' | translate}}<i class="material-icons">person</i>
    </button>
  </div>
  <div fxLayout="row" fxLayoutWrap>
    <button class="menu-buttons signout-button" mat-raised-button (click)="signOut()">
      {{'MENU.LOGOUT' | translate}}<i class="material-icons">logout</i></button>
  </div>
</div>