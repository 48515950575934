
<app-header></app-header>
<div class="user-page">

  <img class="user-icon" src="../../../assets/images/person.svg" />
  <form class="user-form">
    <mat-form-field appearance="fill" class="user-form-field">
      <mat-label>{{'SIGNUP.NAME' | translate}}</mat-label>
      <input matInput placeholder="{{'SIGNUP.NAME' | translate}}" value="{{name}}" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="user-form-field">
      <mat-label>{{'SIGNUP.SURNAME' | translate}}</mat-label>
      <input matInput placeholder="{{'SIGNUP.SURNAME' | translate}}" value="{{surname}}" readonly>
    </mat-form-field>

    <mat-form-field class="user-form-field" appearance="fill">
      <mat-label>{{'SIGNUP.EMAIL' | translate}}</mat-label>
      <input matInput placeholder="{{'SIGNUP.EMAIL' | translate}}" value="{{email}}" readonly>
    </mat-form-field>

    <mat-form-field class="user-form-field" appearance="fill">
      <mat-label>{{'SIGNUP.ADDRESS' | translate}}</mat-label>
      <input matInput placeholder="{{'SIGNUP.ADDRESS' | translate}}" value="{{address}}" readonly>
    </mat-form-field>
  </form>

  <br>
<button *ngIf="!userId || !token" mat-raised-button class="change-password-btn" appearance="fill" routerLink="/change-password">{{'CHANGE_PASSWORD.SUBMIT' | translate}}</button>
<button *ngIf="userId && token" mat-raised-button class="change-password-btn" appearance="fill" [routerLink]="['/change-password']" [queryParams]="{ userId: userId, token: token }">{{'CHANGE_PASSWORD.SUBMIT' | translate}}</button>

</div>