<app-header></app-header>
<div class="div-containers">
  <div class="search-bg">
    <img src="../../assets/icons/search.svg" />
    <input type="search" [(ngModel)]="searchValue"  (input)="onSearchInput()"/>
  </div>
  <div class="btn-categories-container">
    <button mat-flat-button
      [ngClass]="getButtonClass('all')"
      (click)="selectAllCategories()"
      >{{'CLIENT.ALL_CATEGORIES' | translate}}
    </button>
    <button mat-flat-button *ngFor="let category of categories"
      [ngClass]="getButtonClass(category.name)"
      (click)="selectCategory(category)">
      <span>{{category.name}}</span>
    </button>
  </div>
  <div class="page-title">
    Restorantet
  </div>
  <div class="main-container">
    <div class="restaurant-container">
        <div *ngFor="let restaurant of filteredRestaurants" class="restaurant-item" (click)="navigateToRestaurant(restaurant[name], restaurant['token'], restaurant['id'], restaurant['image'])">
            <div class="restaurant-image">
                <img [src]="img_path + restaurant.image" [alt]="restaurant[name] + ' Logo'" (error)="onImageError($event)">
            </div>
            <div class="restaurant-name">{{ restaurant[name] }}</div>
        </div>
    </div>
  </div>
</div>