import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-confirmation-failed',
  templateUrl: './email-confirmation-failed.component.html',
  styleUrls: ['./email-confirmation-failed.component.css']
})
export class EmailConfirmationFailedComponent {
  constructor(
    private router: Router,
  ) {

  }
  goToHome(){
    this.router.navigate(['/home']);
  }
}
