<mat-dialog-content>
    <mat-list>
        <mat-list-item>
            <div class="flex-space-between-width-100">
                <span class="orange-color">{{'EDIT_ORDER_MODAL.MODIFY_ORDER' | translate}}</span>
                <div (click)="closeDialog()">
                    <img height="12" width="12" class="mb-10" src="../../assets/icons/x.svg"/>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item class="h-250">
            <textarea class="textarea-bg" matInput rows="5" (change)="inputChange($event)" [value]="comment" cols="40" placeholder=""></textarea>
        </mat-list-item>
        <mat-list-item>
            <button mat-flat-button class="btn-confirm" (click)="confirm()">
                {{'EDIT_ORDER_MODAL.CONFIRM' | translate}}
            </button>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>