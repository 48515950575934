import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private route: ActivatedRoute) {
    this.refreshToken();
  }
  token;
  companyName:string=localStorage.getItem('companyName')
  async isAuthenticated(): Promise<boolean> {
    const token = await localStorage.getItem('token');
    return token ? true : false;
  }

  // from here we get our token from url
  async refreshToken(): Promise<any> {
    await this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        const tokenParameterName = Object.keys(params).find(key => key.toLowerCase() === 'token');
        this.token = params[tokenParameterName];
        localStorage.setItem('token', JSON.parse(this.token));        
      }
    });
  }
  
}
