import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-error-modal',
  templateUrl: './order-error-modal.component.html',
  styleUrls: ['./order-error-modal.component.css']
})
export class OrderErrorModalComponent {

  constructor(
    private dialogRef: MatDialogRef<OrderErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private router: Router) {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}