import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IOrder } from '../interfaces/IOrder';

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  id: any;
  companyId: any;
  authorization: any;
  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.getToken();
  }

  get_Menu(id: any, currentPage: any, productsPerPage: any) {
    return this.http.get(environment.API_URL + '/MenuApi/GetMenuData', {
      params: {
        companyId: id,
        page: currentPage,
        pageSize: productsPerPage
      },
    });
  }

  getProductsByCategory(id: any, currentPage: any, productsPerPage: any, categoryId) {
    return this.http.get(environment.API_URL + '/MenuApi/GetMenuDataByCategoryId', {
      params: {
        companyId: id,
        page: currentPage,
        pageSize: productsPerPage,
        categoryId: categoryId
      },
    });
  }
  

  getAllCategories(id: any) {
    return this.http.get(environment.API_URL + '/MenuApi/GetAllCategories', {
      params: {
        companyId: id
      },
    });
  }
  

  getAllCompanies() {
    return this.http.get(environment.API_URL + '/MenuApi/GetAllCompanies');
  }

  getAllrestaurantsBycategory () {
    return this.http.get(environment.API_URL + '/MenuApi/GetAllCompaniesByCategory');
  }

  getToken() {
    let token = localStorage.getItem('Token');
    this.authorization = JSON.parse(token);
  }

  createOrder(order: IOrder, email?: string) {
    return this.http.post(environment.API_URL + '/OrdersApi/CreateOrder', order, 
      { params: { email: email } } );
  }

  sendOrderEmail(orderId: number, userId: number) {
    userId = !userId ? -1 : userId;
    const data = { orderId, userId};
    return this.http.post(environment.API_URL + '/OrdersApi/sendOrderConfirmationEmail', data);
  }
  

  //send request to backend with token stored in header to get companyID from getusersposCompanyId
  getCompanyId() {
    return this.http.get(
      environment.API_URL + '/PosUsers/GetPosUsersCompanyId',
      {
        headers: new HttpHeaders({ "authorization": this.authorization.token })
      }
    )
  }

  getCompanyIdByName(name: string) {
    return this.http.get(environment.API_URL + '/MenuApi/GetCompanyId', {
      params: { companyName: name },
    });
  }

  getNewCompanyID(token: string): Observable<any>{
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.get<any>(environment.API_URL+ '/MenuApi/GetNewCompanyId', { headers })

  }

  checkOut(amount: number, orderId: number, baseUrl: string) {
    return this.http.get(environment.API_URL + '/OrdersApi/Checkout',{ params: { amount: amount, orderId: orderId, baseUrlUI: baseUrl } });
  }

  getOrders(email: string, token: string){
    return this.http.get(environment.API_URL + '/OrdersApi/GetClientOrders',{ params: { email: email, token: token } });
  }
}
