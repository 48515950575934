import { Component, OnInit } from '@angular/core';
import { UserService } from '../Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  private readonly SOURCE = 'menuApp';

  forgotPasswordForm = new FormGroup({

    Username : new FormControl(''),
    
  });
  labelError:string = '';
  labelInfo:string = '';


  constructor(private userService: UserService, private translate: TranslateService) { }

  ngOnInit() {
    
  }

  forgotpassword(username) {
    const data = { ...username, Source: this.SOURCE };

    this.userService.forgotpassword(data).subscribe({
      next: (res) => {
        this.labelError = '';
        this.labelInfo='FORGOT_PASSWORD.EMAIL_VALID'
      },
      error: (error) => {
        this.labelInfo='';
        this.labelError = 'FORGOT_PASSWORD.EMAIL_NOT_VALID';
      },
    });
  }

}
