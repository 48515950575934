import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IntroModalComponent } from '../intro-modal/intro-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {


  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.openIntroModal();

    setTimeout(() => {
      this.closeIntroModal();
    }, 2000);
  }

  openIntroModal(): void {
    const introModalRef = this.dialog.open(IntroModalComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      panelClass: 'intro-screen-dialog',
    });

    introModalRef.afterClosed().subscribe(() => {
    });
  }

  closeIntroModal(): void {
    this.dialog.closeAll();
  }

  toLogin() {
    this.router.navigate(['/signin']);
  }

  toRegister() {
    this.router.navigate(['/signup']);
  }

  toMenu() {
    this.router.navigate(['/', 'restaurants']);
  }
}