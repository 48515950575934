<div class="modal-container">
    <div class="header">
      <div class="spacer"></div>
      <div class="close-button" (click)="closeDialog()">
        <img height="12" width="12" src="../../assets/icons/x.svg" />
      </div>
    </div>
    
    <div class="content">
        <img
        src="../assets/images/successOrder.png"
        class="success-image"
        alt="Success Order Image"
      />
      <p class="success-text">{{'PAYMENT_MODAL.ORDER_SUCCESSFUL' | translate}}</p>

      <p class="order-number-text">{{'PAYMENT_MODAL.ORDER_NO' | translate}} {{orderId}}</p>

      <p class="confirmation-text">
        {{'PAYMENT_MODAL.ORDER_MAIL_SENT' | translate}} <span class="email-text"> {{ email }}</span>
      </p>
      <button class="ok-button" (click)="closeDialog()">{{'SIGNUP.OK' | translate}}</button>
    </div>
  </div>
  