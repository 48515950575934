import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../Services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ClientAccessGuard implements CanActivate {

  constructor(
    private navigationService: NavigationService, 
    private router: Router){}

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): boolean {
      // Check if a specific client page was directly accessed
      const isClientPageDirectlyAccessed = this.isDirectClientPageAccess();
      const originalUrl = localStorage.getItem("originalUrl")
  
      if (isClientPageDirectlyAccessed && state.url !== originalUrl) {
        // If client page was directly accessed, prevent access to /restaurants
        this.router.navigateByUrl(originalUrl);
        return false;
      }

      /* if (isClientPageDirectlyAccessed && state.url === '/restaurants') {
        // If client page was directly accessed, prevent access to /restaurants
        this.router.navigate(['/']);
        return false;
      }
   */
      return true;
    }
  
    private isDirectClientPageAccess(): boolean {
      // Check if a specific client page was directly accessed
      const clientPageAccessKey = 'client_page_direct_access';
      return localStorage.getItem(clientPageAccessKey) === 'true';
    }
  
}
