<div class="modal-container">
    <div class="header">
      <div class="spacer"></div>
      <div class="close-button" (click)="closeDialog()">
        <img height="12" width="12" src="../../assets/icons/x.svg" />
      </div>
    </div>
    
    <div class="content">
        <img
        src="../assets/images/errorOrder.png"
        class="error-image"
        alt="Error Order Image"
      />
      <p class="error-text">Error</p>

      <p class="confirmation-text">
        {{ "PAYMENT_MODAL.ORDER_NOT_SUCCESSFUL" | translate }}
      </p>
      <button class="try-button" (click)="closeDialog()">{{ "PAYMENT_MODAL.RETRY" | translate }}</button>
    </div>
  </div>
  