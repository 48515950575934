import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../Services/navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  selectedLanguage: string;
  isLogged:boolean = false;
  displayName:string = "";
  itemsNr: number = 0;
  directAccess:any;


  constructor(private translate: TranslateService, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {

  }

  @Input() showSearch : boolean= true;

  ngOnInit(): void {
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
      localStorage.setItem('selectedLanguage', this.selectedLanguage)
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.translate.setDefaultLang(this.selectedLanguage);
    this.isLogged = !!localStorage.getItem("token");
    this.displayName = localStorage.getItem("displayName") || "";

    const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
    this.itemsNr = cartItems.length;
  }

  changeLanguage(event: MatSelectChange) {
    localStorage.setItem('selectedLanguage', event.value);
    this.selectedLanguage = event.value;
    this.translate.use(this.selectedLanguage);
    this.translate.setDefaultLang(this.selectedLanguage);
    this.translate.onLangChange.emit(event.value)
  }
  isComingFrom(): boolean {
    const clientPageAccessKey = 'client_page_direct_access';
    this.directAccess = localStorage.getItem(clientPageAccessKey);
    return this.directAccess === "false"; 
  }

  navigateBack() {
    let back = localStorage.getItem("companyName");
    this.router.navigate(['/restaurants']);
  }
}
