import { Component, OnInit } from '@angular/core';
import { RestaurantService } from '../Services/restaurantService.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css'],
})
export class PreviewComponent implements OnInit {
  constructor(private menuService: RestaurantService) {
    this.nameLang = localStorage.getItem('lang')
    if (this.nameLang === 'al') {
      this.name = 'nameSQ'
    } else if (this.nameLang === 'mk') {
      this.name = 'nameMK'
    } else {
      this.name = 'nameSQ'
    }
  }
  company: any;
  nameLang: string;
  name = 'nameSQ';



  menuItems = JSON.parse(localStorage.getItem('MenuList'));
  imgLogo = localStorage.getItem('companyLogo');
  ngOnInit() {

    this.menuService.getCompanyId().subscribe((res: any) => {

      this.company = res;
      // this.imgLogo = environment.IMG_PATH_LOGO + this.company.logo;
    });
  }

  albLang() {
    this.name = 'nameSQ';
    localStorage.setItem('Language', JSON.stringify(this.name));
    localStorage.setItem('lang', 'al');

  }
  mkdLang() {
    this.name = 'nameMK';
    localStorage.setItem('Language', JSON.stringify(this.name));
    localStorage.setItem('lang', 'mk');


  }
}
