<mat-dialog-content>
    <form [formGroup]="paymentForm">
        <mat-radio-group formControlName="paymentMethod" aria-label="Select an option">
            <div (click)="selectPaymentMethod(1)" [ngClass]="this.paymentForm.get('paymentMethod').value === 1 ? 'img-radio-container-orange' : 'img-radio-container-grey'">
                <div>
                    <img class="custom-img" [src]="this.paymentForm.get('paymentMethod').value === 1 ? '../../assets/icons/card.svg' : '../../assets/icons/card-grey.svg'"/>
                    <span [ngClass]="this.paymentForm.get('paymentMethod').value === 1 ? 'span-bold' : 'span-light-grey'">{{'PAYMENT_MODAL.CREDIT_CARD' | translate}}</span> 
                </div>
                <mat-radio-button class="custom-radio" [value]="1"></mat-radio-button>
            </div>
            <div (click)="selectPaymentMethod(0)" [ngClass]="this.paymentForm.get('paymentMethod').value === 0 ? 'img-radio-container-orange' : 'img-radio-container-grey'">
                <div>
                    <img class="custom-img" [src]="this.paymentForm.get('paymentMethod').value === 0 ? '../../assets/icons/wallet-orange.svg' : '../../assets/icons/wallet.svg'"/>
                    <span [ngClass]="this.paymentForm.get('paymentMethod').value === 0 ? 'span-bold' : 'span-light-grey'">{{'PAYMENT_MODAL.CASH' | translate}}</span>
                </div> 
                <mat-radio-button class="custom-radio" [value]="0"></mat-radio-button>
            </div>                                  
        </mat-radio-group>
        <mat-list>
            <div *ngIf="!isLogged">
                <mat-list-item class="two-column-layout">
                    <div class="flex-column">
                        <label for="first-name" class="span-grey-bold-600 ">{{'PAYMENT_MODAL.NAME' | translate}}</label>
                        <input id="first-name" type="text" formControlName="firstName" (change)="inputChange($event)" class="input-grey-lighter p-0" placeholder="{{'PAYMENT_MODAL.NAME' | translate}}" [ngClass]="{'invalid-input': paymentForm.get('firstName').invalid && paymentButton}" required/>
                    </div>
                    <div class="flex-column">
                        <label for="last-name" class="span-grey-bold-600">{{'PAYMENT_MODAL.SURNAME' | translate}}</label>
                        <input id="last-name" type="text" class="input-grey-lighter p-0" formControlName="lastName" (change)="inputChange($event)" placeholder="{{'PAYMENT_MODAL.SURNAME' | translate}}" [ngClass]="{'invalid-input': paymentForm.get('lastName').invalid && paymentButton}" required/>
                    </div>
                </mat-list-item>
                
                <mat-list-item>
                    <div class="flex-left-w-100 mt-20">
                        <span class="span-grey-bold">{{'PAYMENT_MODAL.DELIVERY_ADDRESS' | translate}}</span>
                    </div>
                </mat-list-item>
                <mat-list-item>
                    <div class="flex-space-w-100 mt-20">
                        <div class="flex-center">
                            <img class="img-h-20" src="../../assets/icons/motordelivery.svg"/>
                            <input formControlName="address" type="text" (change)="inputChange($event)" class="input-address" #addressInput placeholder="Ilindenska 1220, Tetovo 1200…" [ngClass]="{'invalid-input': paymentForm.get('address').invalid && paymentButton}" required />
                        </div>
                    </div>
                </mat-list-item>
                <div class="flex-row">
                    <mat-divider *ngFor="let hr of lines"></mat-divider>
                </div>
                <mat-list-item class="h-35">
                    <span class="span-grey-bold-mr">{{'PAYMENT_MODAL.EMAIL' | translate}}</span>
                </mat-list-item>
                <mat-list-item>
                    <input formControlName="email" type="email" (change)="inputChange($event)" class="input-grey-mr" placeholder="example@gmail.com" [ngClass]="{'invalid-input': paymentForm.get('phone').invalid && paymentButton}" required />
                </mat-list-item>
                <mat-list-item class="h-35">
                    <span class="span-grey-bold-mr">{{'PAYMENT_MODAL.PHONE_NUMBER' | translate}}</span>
                </mat-list-item>
                <mat-list-item>
                    <input formControlName="phone" type="text" (change)="inputChange($event)" class="input-grey-mr" placeholder="070-xxx-xxx" [ngClass]="{'invalid-input': paymentForm.get('phone').invalid && paymentButton}" required />
                </mat-list-item>
            </div>
            <div class="flex-row-pb">
                <mat-divider *ngFor="let hr of lines"></mat-divider>
            </div>
            <mat-list-item>
                <div class="flex-space-w-100 pt-25">
                    <span class="span-orange">{{'PAYMENT_MODAL.DELIVERY_TIME' | translate}}:</span>
                    <span class="span-grey">25-30 {{'PAYMENT_MODAL.MINUTES' | translate}}</span>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="flex-space-w-100 mb-20">
                    <span class="span-orange">{{'PAYMENT_MODAL.SERVICE' | translate}}:</span>
                    <span class="span-grey">0 {{'CLIENT.CURRENCY' | translate}}</span>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="flex-space-w-100">
                    <span class="span-grey-bold">{{'PAYMENT_MODAL.TOTAL' | translate}}</span>
                    <span class="span-lg-grey-bold">{{totalPayment | roundDecimal}} {{'CLIENT.CURRENCY' | translate}}</span>
                </div>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button class="btn-confirm" [disabled]="this.data.products.length === 0" (click)="payment()">
                    {{'PAYMENT_MODAL.PAY' | translate}}
                </button>
            </mat-list-item>
        </mat-list>
    </form>
</mat-dialog-content>
<form method="post" [action]="PortalUrl" #paymentFormView class="hidden-form">
    <input type="hidden" name="Amount" [value]="amount" />
    <input type="hidden" name="BillToCompany" value="BillToCompany" />
    <input type="hidden" name="CallbackUrl" [value]="CallbackUrl" />
    <input type="hidden" name="Clientid" [value]="Clientid" />
    <input type="hidden" name="OkUrl" [value]="OkUrl" />
    <input type="hidden" name="FailUrl" [value]="FailUrl" />
    <input type="hidden" name="Trantype" [value]="Trantype" />
    <input type="hidden" name="Instalment" [value]="Instalment" />
    <input type="hidden" name="Currency" [value]="Currency" />
    <input type="hidden" name="Rnd" [value]="Rnd" />
    <input type="hidden" name="Storetype" [value]="Storetype" />
    <input type="hidden" name="Lang" [value]="Lang" />
    <input type="hidden" name="hashAlgorithm" value="ver3" />
    <input type="hidden" name="BillToName" value="name" />
    <input type="hidden" name="Refreshtime" [value]="Refreshtime" />
    <input type="hidden" name="Hash" [value]="Hash" />
</form>