import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  selectedLanguage: string;
  isLogged:boolean = false;
  companyName:string;
  previousUrl: string | undefined;
  constructor(
    private lokacioni: Location,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
    ) {}
  
  ngOnInit(): void {
    this.companyName = localStorage.getItem('companyName');

    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.translate.use(this.selectedLanguage);
    this.translate.onLangChange.subscribe(x => {
      this.selectedLanguage = x.lang;
    });
    this.isLogged = !!localStorage.getItem("token");
  }

  backClicked() {
    this.lokacioni.back();
  }

  signOut(){
    localStorage.removeItem("token");
    localStorage.removeItem("displayName");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    this.isLogged = false;
    this.router.navigate(['/', 'restaurants']);
  }
}
