import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../Services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-success-modal',
  templateUrl: './register-success-modal.component.html',
  styleUrls: ['./register-success-modal.component.css']
})

export class RegisterSuccessModalComponent {
  email: string;
  signUpFormData: any;
  emailResent: boolean = false;
  emailVerified: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<RegisterSuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private userService: UserService,
    private router: Router
  ) {
    this.email = data.email;
    this.signUpFormData = data.signUpFormData;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  resendVerificationEmail() {
    const user = this.signUpFormData;
    this.userService.resendVerificationEmail(user).subscribe({
      next: (response) => {
        this.emailResent = true;      
      },
      error: (error) => {
        if (error.status === 400 && error.error && error.error.messa === 'Email is already verified') {
          this.emailVerified = true;
        }
      }
    });
  }

  toLogin() {
    this.closeDialog()
    this.router.navigate(['/signin']);
  }
}
