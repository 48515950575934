import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavigationService } from './Services/navigation.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponentComponent } from './admin-component/admin-component.component';
import { ClientComponentComponent } from './client-component/client-component.component';
import { FooterComponent } from './Footer/Footer/Footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDividerModule } from '@angular/material/divider';
import { PreviewComponent } from './previewMenu/preview.component';
import { CanDeactivateGuard } from './auth-guard/can-deactivate.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SwiperModule } from 'swiper/angular';
import { OrderCartListComponent } from './order-cart-list/order-cart-list.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { EditOrderModalComponent } from './edit-order-modal/edit-order-modal.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RoundDecimalPipe } from './Pipes/round-decimal.pipe';
import { SearchPipe } from './Pipes/search.pipe';
import { FailSuccessModal } from './fail-success-modal/fail-success-modal.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MaterialModule } from './utils/material.module';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { UserComponent } from './user/user.component';
import { RegisterSuccessModalComponent } from './register-success-modal/register-success-modal.component';
import { OrderSuccessModalComponent } from './order-success-modal/order-success-modal.component';
import { OrderErrorModalComponent } from './order-error-modal/order-error-modal.component';
import { HomeComponent } from './home/home.component';
import { IntroModalComponent } from './intro-modal/intro-modal.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { EmailConfirmationSuccessComponent } from './email-confirmation-success/email-confirmation-success.component';
import { EmailConfirmationFailedComponent } from './email-confirmation-failed/email-confirmation-failed.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponentComponent,
    ClientComponentComponent,
    FooterComponent,
    PreviewComponent,
    OrderCartListComponent,
    PaymentModalComponent,
    EditOrderModalComponent,
    ConfirmDialogComponent,
    RoundDecimalPipe,
    SearchPipe,
    FailSuccessModal,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    HeaderComponent,
    MenuComponent,
    MyOrdersComponent,
    UserComponent,
    RegisterSuccessModalComponent,
    OrderSuccessModalComponent,
    OrderErrorModalComponent,
    HomeComponent,
    IntroModalComponent,
    RestaurantsComponent,
    EmailConfirmationSuccessComponent,
    EmailConfirmationFailedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CdkAccordionModule,
    DragDropModule,
    MatIconModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatRadioModule,
    MatFormFieldModule,
    MatDividerModule,
    SwiperModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    CanDeactivateGuard,
    NavigationService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
