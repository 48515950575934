import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { RestaurantService } from '../Services/restaurantService.service';


@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css'],
})
export class MyOrdersComponent implements OnInit {
  selectedLanguage: string;
  isLogged:boolean = false;
  orders: [];
  constructor(private lokacioni: Location, private translate: TranslateService, private menuService: RestaurantService,) {}
  
  ngOnInit(): void {
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.translate.use(this.selectedLanguage);
    this.translate.onLangChange.subscribe(x => {
      this.selectedLanguage = x.lang;
    });
    this.isLogged = !!localStorage.getItem("token");
    this.getOrders();
  }

  getOrders(){
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    this.menuService.getOrders(email, token)
    .subscribe((res: any) => {
      this.orders = res;
    });
  }

  backClicked() {
    this.lokacioni.back();
  }
}
