<app-header [showSearch]="false"></app-header>

<div class="user-page">
  <form class="user-form" [formGroup]="forgotPasswordForm">
  <p class="forgot-password-text">{{'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate}}</p>
  <mat-form-field appearance="fill" class="user-form-field">
    <mat-label>{{'FORGOT_PASSWORD.EMAIL' | translate}}</mat-label>
    <input matInput id="email" placeholder="{{'FORGOT_PASSWORD.EMAIL' | translate}}" value="" formControlName="Username">
  </mat-form-field>
  <div [ngClass]="labelError !== ''? 'visible':'invisible'" class="text-danger">
    {{labelError| translate}}
  </div>
  <div [ngClass]="labelInfo !== ''? 'visible':'invisible'" class="text-info">
    {{labelInfo| translate}}
  </div>
</form>
<br/>
<button mat-raised-button class="forgot-password-btn" appearance="fill" (click)="forgotpassword(forgotPasswordForm.value)">{{'FORGOT_PASSWORD.SUBMIT' | translate}}</button>
  <br>

<a [routerLink]="['/signin']" routerLinkActive="router-link-active" >{{'FORGOT_PASSWORD.ALREADY_HAVE_LOGIN_CREDENTIALS' | translate}}</a>
</div>