import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponentComponent } from './admin-component/admin-component.component';
import { ClientComponentComponent } from './client-component/client-component.component';
import { AuthGuard } from './auth-guard/auth.guard';
import { PreviewComponent } from './previewMenu/preview.component';
import { CanDeactivateGuard } from './auth-guard/can-deactivate.guard';
import { OrderCartListComponent } from './order-cart-list/order-cart-list.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MenuComponent } from './menu/menu.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { UserComponent } from './user/user.component';
import { HomeComponent } from './home/home.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { RestaurantService } from './Services/restaurantService.service';
import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ClientAccessGuard } from './guards/client-access.guard';
import { EmailConfirmationSuccessComponent } from './email-confirmation-success/email-confirmation-success.component';
import { EmailConfirmationFailedComponent } from './email-confirmation-failed/email-confirmation-failed.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponentComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  { path: 'home', component: HomeComponent },
  { path: 'previewMenu', component: PreviewComponent },
  { path: 'signin', component: SignInComponent },
  { path: 'signin/:isAccountActivated', component: SignInComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'verification-successful', component: EmailConfirmationSuccessComponent },
  { path: 'verification-failed', component: EmailConfirmationFailedComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent},
  { path: 'menu', component: MenuComponent },
  { path: 'my-orders', component: MyOrdersComponent,canActivate: [AuthGuard] },
  { path: 'user-profile', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'restaurants', component: RestaurantsComponent, canActivate: [ClientAccessGuard]},
  { path: 'restaurants/:companyName', component: ClientComponentComponent },
  { path: 'restaurants/:companyName/cart', component: OrderCartListComponent },
  { path: 'restaurants/:companyName/:tableNumber', component: ClientComponentComponent},
  { path: 'restaurants/:companyName/:tableNumber/cart', component: OrderCartListComponent },

  { path: '', redirectTo: (localStorage.getItem("token") ? '/restaurants' : '/home'), pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    RestaurantService,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthGuard, 
      multi   : true,
    },
    DatePipe
  ],
})
export class AppRoutingModule {}
