<footer>
  <div class="mdc-layout-grid ">
    <div class="mdc-layout-grid__inner">
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-12-phone text-center ">
        <div class="footer-container">
          <a href="https://conome.mk/"> © conome.mk</a>
        </div>
      </div>
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-12-phone text-center">
        developed by <a href="https://i-solve.info/?ertthndxbcvs=yes">i-solve.info</a>
      </div>
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-12-phone text-center">
        <div class="test">
          <a href="https://www.facebook.com/isolve.info"><img src="../../assets/icons/facebook-icon.svg"
              alt="facebookLogo"></a>
          <a href="https://www.instagram.com/isolve.info/"><img src="../../assets/icons/instagram-logo.svg"
              alt="instagramLogo"></a>
          <a href="https://www.linkedin.com/company/isolve-info/mycompany/"><img
              src="../../assets/icons/linkedIn-logo.svg" target="_blank" alt="facebookLogo"></a>
        </div>
      </div>
    </div>
  </div>
</footer>
