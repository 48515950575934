import { IOrderDetails } from "./IOrderDetails";
import { IOrderClient } from "./IOrderClient";

export interface IOrder {
    companyID: number;
    dateOrder: Date;
    paymentStatus: PaymentStatusEnum;
    orderStatus: OrderStatusEnum;
    paymentType: PaymentTypeEnum;
    total: number;
    orderDetails?: IOrderDetails[];
    isDelivery: boolean;
    orderClient?: IOrderClient
    clientConomeID?: number; 
    tableNumber?: number;
  }

  export enum PaymentStatusEnum {
    Unpaid,
    Paid,
  }
  
  export enum OrderStatusEnum {
    Processing,
    Success,
    Failed,
  }

  export enum PaymentTypeEnum{
    Cash,
    Card
  }