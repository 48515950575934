import { Component, HostListener, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestaurantService } from '../Services/restaurantService.service';
import { Category } from '../categoryObj';
import { ActivatedRoute, CanDeactivate } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-component',
  templateUrl: './admin-component.component.html',
  styleUrls: ['./admin-component.component.scss'],
})
export class AdminComponentComponent implements OnInit {
  categories = new Array<any>();
  removedCategory = [];
  allCategories = [];
  allProducts = [];
  id: any;
  authorization: any;
  alertText: any;
  company: any;
  selectedLanguage = "mk";
  imgLogo = '';
  tempMenuList: any;
  name: string;

  constructor(
    private menuService: RestaurantService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.route.queryParams.subscribe(x => {
      this.selectedLanguage = x.lang
      localStorage.setItem('Lang', x.lang)
      if (x.lang === 'al') {
        this.name = 'nameSQ'
      } else if (x.lang === 'mk') {
        this.name = 'nameMK'
      } else {
        this.name = 'nameSQ'
      }
    })

    localStorage.setItem('lang', this.selectedLanguage)

    this.translate.setDefaultLang(this.selectedLanguage)
    this.translate.get('ADMIN.MENU-PUBLISHED-SUCCESSFULLY').subscribe(data => {
      this.alertText = data
    })
  }
  productsPerPage = 20; // Number of products to load per request
currentPage = 0; // Track the current page for loading
isLoading = false; // Track if data is being loaded to prevent duplicate requests

  //Get data from backend at ngOnInit
  ngOnInit() {

    const token = localStorage.getItem('Token');
    const data = JSON.parse(token);
    if (data.token != '') {
      this.menuService.getCompanyId().subscribe((res: any) => {
        this.id = res.companyID;
        this.menuService.get_Menu(this.id, this.currentPage, this.productsPerPage).subscribe((res: Category[]) => {
          this.categories = res;
          this.allCategories = this.categories.map((x) => {
            return {
              categoryId: x.categoryId,
              hideOnMenuCategory: x.hideOnMenuCategory,
              image: x.image,
              nameMK: x.nameMK,
              nameSQ: x.nameSQ,
              orderIndexCategory: x.orderIndexCategory,
              productCategoryId: x.productCategoryId,
            };
          });
          this.allProducts = [];
          this.categories.map((x) => {
            this.allProducts.push(...x.products);
          });
          localStorage.setItem('MenuList', JSON.stringify(this.categories));
          this.tempMenuList = JSON.stringify(this.categories);
        });
      });
    }
    //get company logo
    this.menuService.getCompanyId().subscribe((res: any) => {
      this.company = res;
      this.imgLogo = environment.IMG_PATH_LOGO + this.company.logo;
    });
  }

  //Send data to backend
  onSaveMenu() {
    let token = localStorage.getItem('Token');
    this.authorization = JSON.parse(token);
    this.http
      .post(
        environment.API_URL + '/MenuApi/UpdateMenuData?companyId=' +
        this.id,
        {
          ProductCategories: this.allCategories,
          products: this.allProducts,
        },
        {
          headers: new HttpHeaders({ "authorization": this.authorization.token })
        }
      )
      .subscribe((res) => {
      });

    Swal.fire({
      title: this.alertText,
      icon: 'success',
      confirmButtonColor: "#7BD361"
    }).then(() => {
      this.tempMenuList = JSON.stringify(this.categories);
    });
    localStorage.removeItem('MenuList');
  }

  //Drag and drop functionalities
  drop(event: CdkDragDrop<Category[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data || [],
        event.previousIndex,
        event.currentIndex
      );
      let categoryOrderIndex = -1;
      if (event.container.id == 'menuList') {
        this.allCategories = event.container.data.map((x) => {
          categoryOrderIndex++;
          return {
            categoryId: x.categoryId,
            hideOnMenuCategory: x.hideOnMenuCategory,
            image: x.image,
            nameMK: x.nameMK,
            nameSQ: x.nameSQ,
            orderIndexCategory: categoryOrderIndex,
            productCategoryId: x.productCategoryId,
          };
        });
      }
      //Update products indexes
      if (event.container.id == 'menuProductsList') {
        this.updateProductIndexes(event.container.data);
      }

      // index of categories
      // if (event.container.id == 'menuList') {
      //   this.updateCategoryIndexes(event.container.data);
      // }
    } else {
      if (event.container.id == 'removedCategories') {
        event.previousContainer.data[event.previousIndex].hideOnMenuCategory =
          true;
      }
      if (event.container.id == 'menuList') {
        event.previousContainer.data[event.previousIndex].hideOnMenuCategory =
          false;
      }

      //transfer here...
      transferArrayItem(
        event.previousContainer.data || [],
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      let categoryOrderIndex = -1;
      this.allCategories = event.container.data.map((x) => {
        categoryOrderIndex++;
        return {
          categoryId: x.categoryId,
          hideOnMenuCategory: x.hideOnMenuCategory,
          image: x.image,
          nameMK: x.nameMK,
          nameSQ: x.nameSQ,
          orderIndexCategory: categoryOrderIndex,
          productCategoryId: x.productCategoryId,
        };
      });
      this.allProducts = [];

      event.container.data.map((x) => {
        this.allProducts.push(...x.products);
      });
    }
  }

  updateProductIndexes(products: any[]): void {
    let orderIndex = 1;
    products.map((x) => {
      x.orderIndex = orderIndex;
      orderIndex++;
      const prodIndex = this.allProducts.findIndex((y) => y.id === x.id);
      if (prodIndex > -1) this.allProducts[prodIndex] = x;
    });
  }

  //update index of categories
  // updateCategoryIndexes(category: any[]): void {
  //   let orderIndex = 1;
  //   category.map((x) => {
  //     x.orderIndexCategory = orderIndex;
  //     orderIndex++;
  //     const catIndex = this.allCategories.findIndex((y) => y.id === x.id);
  //     if (catIndex > -1) this.allCategories[catIndex] = x;
  //   });
  // }

  //Update product hideOnMenu value
  setActive(event: any, categoryIndex: any, productIndex: any): void {
    if (event.checked === false) {
      this.categories[categoryIndex].products[productIndex].hideOnMenu = true;
    } else {
      this.categories[categoryIndex].products[productIndex].hideOnMenu = false;
    }
    if (event.checked === true) {
      this.categories[categoryIndex].products[productIndex].orderIndex = 0;
    } else {
      this.categories[categoryIndex].products[productIndex].orderIndex;
    }
  }

  //Preview Menu
  previewMenu() {
    localStorage.setItem('companyLogo', this.imgLogo);
    localStorage.setItem('MenuList', JSON.stringify(this.categories));
  }

  // Close mat expansion after dragging
  drag(drag, panel) {
    panel.expanded = false;

  }

  //Alert user if reloads the page or navigates away if changes are made
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }


  canDeactivate(): boolean {
    return this.tempMenuList === JSON.stringify(this.categories);
  }
}
