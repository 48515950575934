<div class="header">
  <div class="mdc-layout-grid ">
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-3-tablet ">
      </div>
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-1-tablet mdc-layout-grid__cell--span-12-phone text-center ">
        <img class="companyLogo" src="{{imgLogo}}" alt="Company Logo">
      </div>
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-12-phone  ">
        <div class="test">
          <img src="../../assets/icons/language-icon.svg" alt="LanguageIcon" class="languageIcon">
          <p (click)="albLang()" [class.bold]="name==='nameSQ'"> ALB </p>
          <p>|</p>
          <p (click)="mkdLang()" [class.bold]="name==='nameMK'"> MK </p>
          <!-- <p (click)="englishLang()">
            ENG </p> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="menu">
  <cdk-accordion class="example-accordion">
    <ng-container *ngFor="let item of menuItems; let index = index;">
      <cdk-accordion-item *ngIf="item.hideOnMenuCategory === false" #accordionItem="cdkAccordionItem"
        class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
        <div class="example-accordion-item-header"
          [ngStyle]="{'background-color': accordionItem.expanded? '#706765' : '#DEE1DE'}"
          (click)="accordionItem.toggle()">
          <h4 [ngStyle]="{'color': accordionItem.expanded? '#DAD8C6' : '#706765'}">
            <span class="example-accordion-item-description">
              <img src="../../assets/icons/right-arrow.svg" class="right-arrow" *ngIf="!accordionItem.expanded">
              <img src="../../assets/icons/arrow-up.svg" class="arrow-up" *ngIf="accordionItem.expanded">
            </span>
            {{ item[name] }}
          </h4>
        </div>
        <div class="mdc-layout-grid" *ngIf="accordionItem.expanded">
          <div class="mdc-layout-grid__inner">
            <ng-container *ngFor="let product of item.products">
              <div
                class="example-accordion-item-body mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-12-phone"
                *ngIf="product.hideOnMenu === false" role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">
                <div class="productContainer">
                  <div class="namePrice"> {{product[name]}}</div>
                  <div class="horizontal-line">
                    <hr class="hr-line">
                    <div>{{product.price}}</div>
                  </div>
                </div>
                <div class="notes">{{ product.notes }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </cdk-accordion-item>
    </ng-container>
  </cdk-accordion>
</div>
