import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RestaurantService } from '../Services/restaurantService.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../Services/navigation.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.css']
})
export class RestaurantsComponent implements OnInit {
  img_path = environment.IMG_PATH;
  searchValue: string = '';
  restaurants: any[] = [];
  filteredRestaurants: any[] = [];
  initialFilteredRestaurants: any[] = [];
  categories: any[] = [];

  categorySelected: string = 'all';
  companyName: string;
  nameLang: string;
  name: string;
  categoryName: string;

  constructor(private cdr: ChangeDetectorRef, private navigationService: NavigationService, private restaurantService: RestaurantService, private activatedRoute: ActivatedRoute, private router: Router, private translate: TranslateService,) {
    this.companyName = this.activatedRoute.snapshot.paramMap.get('companyName');
    this.nameLang = localStorage.getItem('selectedLanguage');
    this.name = this.nameLang === 'mk' ? 'nameMK' : 'nameSQ';
    this.categoryName = this.nameLang === 'mk' ? 'categoryNameMK' : 'categoryName';
  }
  defaultImage = environment.DEFAULT_IMAGE

  onImageError(event: Event) {
    const defaultImage = this.defaultImage; 
    (event.target as HTMLImageElement).src = defaultImage;
}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(x => {
      this.nameLang = localStorage.getItem('selectedLanguage');
      this.name = this.nameLang === 'mk' ? 'nameMK' : 'nameSQ';
      this.categoryName = this.nameLang === 'mk' ? 'categoryNameMK' : 'categoryName';
      // this.getAllrestaurantsBycategory();

    });
    // if (this.restaurants.length < 1) {
    //   this.getAllrestaurantsBycategory();
    // }
    this.getAllrestaurantsBycategory();
    localStorage.removeItem('products');
  }

  onSearchInput(): void {
    let currentRestaurants = this.initialFilteredRestaurants;
    const searchTerm = this.searchValue.toLowerCase();
    if (searchTerm.trim() === '') {
      this.filteredRestaurants = this.initialFilteredRestaurants;
    } else {
      currentRestaurants = this.initialFilteredRestaurants;
      this.filteredRestaurants = currentRestaurants.filter(company =>
        company[this.name].toLowerCase().includes(searchTerm)
      );
    }
  }

  getButtonClass(categoryName: string): string {
    return categoryName === this.categorySelected ? 'btn-categories-selected' : 'btn-categories';
  }

  selectAllCategories(): void {
    this.categorySelected = 'all';

    const uniqueCompaniesMap = new Map<number, boolean>();
    this.filteredRestaurants = this.restaurants
      .reduce((acc, curr) => acc.concat(curr.companies), [])
      .filter(company => {
        if (!uniqueCompaniesMap.has(company.id)) {
          uniqueCompaniesMap.set(company.id, true);
          return true;
        }
        return false;
      });

    this.initialFilteredRestaurants = [...this.filteredRestaurants];
  }

  selectCategory(category: any): void {
    this.categorySelected = category.name;
    var selectedCategoryID = category.id;
    const selectedCategory = this.restaurants.find(c => c.categoryId === selectedCategoryID);
    this.filteredRestaurants = selectedCategory ? selectedCategory.companies : [];
    this.initialFilteredRestaurants = [...this.filteredRestaurants];
  }

  getAllrestaurantsBycategory(): void {
    this.restaurantService.getAllrestaurantsBycategory()
      .subscribe({
        next: (data: any) => {
          if (data.length > 0) {
            this.categories = data.map(category => ({
              id: category.categoryId,
              name: category[this.categoryName]
            }));
          }
          this.restaurants = data;

          if (this.categorySelected == 'all') {
            this.selectAllCategories();
          }

        },
        error: (error: any) => {
          console.error('Error fetching restaurants:', error);
        }
      });
  }



  async navigateToRestaurant(restaurant: any, companyToken: string, companyId: any, image: any) {
    try {
      if (!companyToken) {
        console.error("Company token is missing!");
        return;
      }
  
      
      this.cdr.detectChanges();
      // Retrieve companyId based on companyToken
      const newCompanyId = await lastValueFrom(this.restaurantService.getNewCompanyID(companyToken));
  
      localStorage.setItem("companyToken", companyToken);
      localStorage.setItem("companyId", newCompanyId);
      localStorage.setItem("companyLogo", image);
      const clientPageAccessKey = 'client_page_direct_access';
      localStorage.setItem(clientPageAccessKey, 'false');
  
      const route = `/restaurants/${restaurant}`;
      this.router.navigate([route], {
        state: { navigationId: Date.now() },
        queryParams: { companyId: newCompanyId, directAccess: false }
      });
    } catch (err) {
      console.error("Error during navigation:", err); // Handle error if the request fails
    }
  }
}
