import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../Services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Login} from 'src/app/interfaces/IUsers';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  selectedLanguage: string;

  signInForm = new FormGroup({

    Username : new FormControl(''),
    Password : new FormControl(''),
  });
  isAccountActivated: number|null;
  labelError:string='';
  viewPassword: boolean = false;

  constructor(private userService: UserService, private translate: TranslateService,private router: Router,
    private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.translate.use(this.selectedLanguage);
    this.translate.onLangChange.subscribe(x => {
      this.selectedLanguage = x.lang;
      
    });

    // isAccountActivated checks if comes a number as a parameter, it means it's a redirect after a successfull user activation.
    this.isAccountActivated = Number(this.activatedRoute.snapshot.paramMap.get('isAccountActivated'));
  }

  login(data){
    this.labelError = '';
      this.userService.login(data).subscribe((res:Login)=>{
        localStorage.setItem("token",res.token)
        localStorage.setItem("displayName",res.user.displayName)
        localStorage.setItem("userId", res.id.toString())
        localStorage.setItem("email", res.email)
        this.router.navigate(['/restaurants']);
      },
      (error:Login )=>{
        this.labelError = 'SIGN_IN.ERROR-MESSAGE';
      }
      );
  }


  changeLanguage(event) {
    localStorage.setItem('selectedLanguage', event.target.value);
    this.selectedLanguage = event.target.value;
    this.translate.use(this.selectedLanguage);
    this.translate.setDefaultLang(this.selectedLanguage)

  }

  passwordShowHide(){
    this.viewPassword = !this.viewPassword;
  }
}
