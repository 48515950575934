import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundDecimal'
})
export class RoundDecimalPipe implements PipeTransform {
  
  transform(value: number | null): number | null {
    if(value === null || isNaN(value)) {
      return null;  
    }
    return Math.floor(value * 10) /10;
  }

}
