import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { EditOrderModalComponent } from '../edit-order-modal/edit-order-modal.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';
import { RestaurantService } from '../Services/restaurantService.service';

@Component({
  selector: 'app-order-cart-list',
  templateUrl: './order-cart-list.component.html',
  styleUrls: ['./order-cart-list.component.css']
})
export class OrderCartListComponent implements OnInit {
  img_path = environment.IMG_PATH;
  name: string;
  color: string;
  selectedMenu = [];
  deliveryPickup: boolean = true;
  totalPrice = 0;
  tableNumber: number;
  directAccess: any;

  constructor(
      public dialog: MatDialog, 
      private router: Router, 
      private activatedRoute: ActivatedRoute,
      private restaurantService: RestaurantService) {
        this.tableNumber = Number(this.activatedRoute.snapshot.paramMap.get('tableNumber'));
      }

  ngOnInit(): void {
    const clientPageAccessKey = 'client_page_direct_access';
    this.directAccess = localStorage.getItem(clientPageAccessKey);
    this.selectedMenu = JSON.parse(localStorage.getItem('products') || "[]");
  }

  openPayment(): void {
    const hasProduct= localStorage.getItem('products')
    this.selectedMenu = JSON.parse(localStorage.getItem('products'))
    if(hasProduct && hasProduct.length > 2){
      const dialogRef = this.dialog.open(PaymentModalComponent, {
        panelClass: 'payment-dialog',
        data: { products: this.selectedMenu, total: this.totalPrice, deliveryOrPickup: this.deliveryPickup, companyName: this.activatedRoute.snapshot.params.companyName, tableNumber: this.tableNumber },
      });
      dialogRef.afterClosed().subscribe((res) => {});
    }
  }

  openEditOrder(modifiedProduct): void {
    const dialogRef = this.dialog.open(EditOrderModalComponent, {
      panelClass: 'edit-order-dialog',
      data: { product: modifiedProduct },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  increaseQuantity(product) {
    product.quantity++;
    this.updateLocalStorage();
  }
  decreaseQuantity(product) {
    if (product.quantity > 1) {
      product.quantity--;
      this.updateLocalStorage();
    }
  }

  updateLocalStorage() {
    localStorage.setItem('products', JSON.stringify(this.selectedMenu));
  }

  openConfirmDialog(data: { product: any, index: number }): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data : { product: data.product, index: data.index }
    })

    dialogRef.afterClosed().subscribe(index => {
      if (index >= 0 && index < this.selectedMenu.length) {
        this.selectedMenu.splice(index, 1);
        localStorage.setItem('products', JSON.stringify(this.selectedMenu));
      }
    })
  }

  changeDeliveryPickup(deliverOrPick) {
    this.deliveryPickup = deliverOrPick;
  }

  total() {
    this.totalPrice = this.selectedMenu.reduce((sum, prod) => {
      const matchedOption = prod.options?.find(option => option.id === prod.productDetailID);
      const priceToUse = matchedOption ? matchedOption.sellingPriceWithVat : prod.sellingPriceWithVat;
      return sum += priceToUse * prod.quantity;
    }, 0);
  
    return this.totalPrice;
  }

  navigateBack() {
    let restaurantName = localStorage.getItem("companyName");
    if (this.tableNumber) {
      this.router.navigate(['/restaurants', restaurantName, this.tableNumber],
        { queryParams: { directAccess: this.directAccess === "false" ? false : true }}
      );
    } else {
      this.router.navigate(['/restaurants', restaurantName],
        { queryParams: { directAccess: this.directAccess === "false" ? false : true }}
      );
    }
  }
}
