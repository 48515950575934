import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { getPlaces } from 'src/app/interfaces/IUsers';
import { RegisterSuccessModalComponent } from '../register-success-modal/register-success-modal.component';
import { MatDialog } from '@angular/material/dialog';

const PASSWORD_MIN_LENGTH = 8;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  selectedLanguage: string;
  places:getPlaces[] = [];
  contactDataForm: FormGroup;
  mandatoryFields = [
    "firstName",
    "lastName",
    "Address",
    "addressNumber",
    "bankName",
    "IBAN",
    "SWIFT",
    "accountNumber",
    "phoneNumber"
  ];
  isFormSubmitted: boolean = false;
  registerSuccess: string = "";
  registerFail: string = "";
  viewPassword: boolean = false;
  
  signUpForm = new FormGroup({
    Name:new FormControl('',[Validators.required]),
    Surname: new FormControl('',[Validators.required]),
    Email : new FormControl('', [Validators.required, Validators.email]),    
    PlaceId : new FormControl(''),
    Password : new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(PASSWORD_MIN_LENGTH),
      this.patternValidator(new RegExp("(?=.*[0-9])"), {
        requiresDigit: true
      }),
      this.patternValidator(new RegExp("(?=.*[A-Z])"), {
        requiresUppercase: true
      }),
      this.patternValidator(new RegExp("(?=.*[a-z])"), {
        requiresLowercase: true
      }),
      this.patternValidator(new RegExp("(?=.*[$#@^!%*?&])"), {
        requiresSpecialChars: true
      })
    ])
    ),
    ConfirmPassword : new FormControl(''),
    Address : new FormControl(''),
    AddressNumber : new FormControl('',[Validators.required]),
    BankName : new FormControl(''),
    IBAN : new FormControl(''),
    SWIFT : new FormControl(''),
    BankAccount : new FormControl(''),
    PhoneNumber : new FormControl('')
    
  },
  {
    updateOn: 'blur',
  });

  constructor(private userService: UserService,private translate: TranslateService, private dialog: MatDialog) { }

  ngOnInit(): void {
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.translate.use(this.selectedLanguage);
    this.translate.onLangChange.subscribe(x => {
      this.selectedLanguage = x.lang;
      
    });

    this.contactDataForm = new FormGroup({})
        this.mandatoryFields.forEach(value => this.contactDataForm.addControl(value, new FormControl("", Validators.required)));
        this.getAllPlaces(); 
        
  }

  getAllPlaces(){
    this.userService.getAllPlaces().subscribe((data)=>{
      this.places = data;
    })
  }  

  changeLanguage(event) {
    localStorage.setItem('selectedLanguage', event.target.value);
    this.selectedLanguage = event.target.value;
    this.translate.use(this.selectedLanguage);
    this.translate.setDefaultLang(this.selectedLanguage)    
  }

  register(data){
    this.isFormSubmitted = true;
    if(this.signUpForm.invalid) return;
    
    this.registerSuccess = "";
    this.registerFail = "";
    this.userService.register(data).subscribe(res =>{
      this.dialog.open(RegisterSuccessModalComponent, {
        data: {
          email: data.Email,
          signUpFormData: data
        },
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        panelClass: 'full-screen-dialog'
      });
      this.signUpForm.reset();
    },
    error=>{
      const theError = error as HttpErrorResponse;
      this.registerFail = theError.error.messa;
    })
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if the control value is empty return no error.
        return null;
      }

      // test the value of the control against the regexp supplied.
      const valid = regex.test(control.value);

      // if true, return no error, otherwise return the error object passed in the second parameter.
      return valid ? null : error;
    };
  }

  /**
   * Makes the first character to be uppercase
   * @param control //the controlName of the form to set the updatedText
   * @param currentValue // is the vslue that need to be made uppercase
   */
  makeFirstLetterUpperCase(control:string, currentValue){
    const formValue = currentValue.target.value; //retrieves the value
    const updatedText = formValue.charAt(0).toUpperCase() + formValue.slice(1); //makes the first character uppercase
    this.signUpForm.controls[control].setValue(updatedText) // sets the control value as the new updated task
  }

  passwordShowHide(){
    this.viewPassword = !this.viewPassword;
    
  }


}
