import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-order-modal',
  templateUrl: './edit-order-modal.component.html',
  styleUrls: ['./edit-order-modal.component.css']
})
export class EditOrderModalComponent implements OnInit {
  comment: string;
  constructor(
    public dialogRef: MatDialogRef<EditOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  closeDialog(): void{
    this.dialogRef.close();
  }
  confirm(): void {
    const productId = this.data.product.id;
    const prodcutPrice = this.data.product.price;
    const productList = JSON.parse(localStorage.getItem('products') || '[]');
    const foundProductIndex = productList.findIndex(product => product.id === productId && product.price === prodcutPrice);
    if (foundProductIndex !== -1) {
      const productToUpdate = productList[foundProductIndex];
      productToUpdate.comment = this.comment;
      productList[foundProductIndex] = productToUpdate;
      localStorage.setItem('products', JSON.stringify(productList));
    }
    this.dialogRef.close(this.comment);
  }

  inputChange(event){
    this.comment = event.target.value;
  }

  ngOnInit() {
    const productId = this.data.product.id;
    const prodcutPrice = this.data.product.price;
    const productList = JSON.parse(localStorage.getItem('products') || '[]');
    const foundProductIndex = productList.findIndex(product => product.id === productId && product.price === prodcutPrice);
    if (foundProductIndex !== -1) {
      const productToUpdate = productList[foundProductIndex];
      const productComment = productToUpdate.comment;
      if(productComment !== undefined && productComment !== null && productComment !== ""){
        this.comment = productComment;
      }else{
        this.comment = "";
      }
    }else{
      this.comment = "";
    }
  }
}
