import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private previousUrl: string = '';
  private currentUrl: string = '';
  private validNavigationSources: string[] = ['/restaurants'];

  constructor(private router: Router) {
    this.initializeUrlTracking();
  }

  private initializeUrlTracking() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Only update previous URL if the new URL is different
      if (event.url !== this.currentUrl) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  /**
   * Get the previous URL
   * @returns {string} The previous URL
   */
  getPreviousUrl(): string {
    return this.previousUrl;
  }

  /**
   * Check if the current navigation is from a valid source route
   * @returns {boolean} Whether the navigation is from a valid source
   */
  isValidNavigation(): boolean {
    return this.validNavigationSources.some(route => 
      this.previousUrl.startsWith(route)
    );
  }

  /**
   * Navigate back to the previous page if it exists and meets certain conditions
   * @param fallbackRoute Route to navigate to if no previous page exists
   * @param allowedOriginRoutes Array of routes that are allowed to trigger back navigation
   */
  navigateBack(fallbackRoute: string = '/', allowedOriginRoutes: string[] = ['/restaurants']) {
    if (this.previousUrl && 
        allowedOriginRoutes.some(route => this.previousUrl.startsWith(route))) {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.router.navigateByUrl(fallbackRoute);
    }
  }

  /**
   * Check if the current navigation is from a specific route
   * @param route Route to check
   * @returns {boolean} Whether the current navigation is from the specified route
   */
  isComingFrom(route: string): boolean {
    return this.previousUrl === route
  }
}
