<div class="modal-container">
    <div class="header">
      <div class="spacer"></div>
      <div class="close-button" (click)="closeDialog()">
        <img height="12" width="12" src="../../assets/icons/x.svg" />
      </div>
    </div>
    
    <div class="content">
        <img
        src="../assets/images/succesRegistration.png"
        class="success-image"
        alt="Success Registration Image"
      />
      <p class="success-text">{{'SIGNUP.SINUP_SUCCESSFUL' | translate}}</p>
      <p class="confirmation-text" *ngIf="!emailResent">
        {{'SIGNUP.SUCCESS_MAIL_SENT' | translate}} <span class="email-text"> {{ email }}</span>
      </p>
      <p class="confirmation-text" *ngIf="emailResent && !emailVerified">
        {{'SIGNUP.SUCCESS_MAIL_RESENT' | translate}} <span class="email-text"> {{ email }}</span>
      </p>
      <p class="confirmation-text" *ngIf="emailVerified">
        {{'SIGNUP.EMAIL_VERIFIED' | translate}}
      </p>
      <button class="ok-button" *ngIf="!emailVerified" (click)="closeDialog()">{{'SIGNUP.OK' | translate}}</button>
      <p class="resend-text" *ngIf="!emailVerified" (click)="resendVerificationEmail()">{{'SIGNUP.RESEND' | translate}}</p>

      <button class="ok-button" *ngIf="emailVerified" (click)="toLogin()">{{'SIGNUP.OK' | translate}}</button>
      <p class="resend-text" *ngIf="emailVerified" (click)="toLogin()">{{'SIGNUP.LOGIN' | translate}}</p>
    </div>
  </div>
  