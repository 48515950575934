<div class="div-containers">
    <div class="cart-list-container">
        <div class="flex-space">
            <img class="img-back" src="../../assets/icons/back.svg" (click)="navigateBack()"/>
            <img src="../../assets/icons/cart2.svg"/>
        </div>
        <div class="custom-header" *ngIf="selectedMenu.length == 0">
            <h2>{{'ORDER_CART.EMPTY_PRODUCT' | translate}}</h2>
        </div>
        <mat-grid-list cols="4" rowHeight="1:1" *ngFor="let selMenu of selectedMenu; let i = index">
            <mat-grid-tile colspan="1">
                <div class="img-container">
                <img class="img-list" [src]="img_path + selMenu.image" alt="Img" />
                    
                </div>
            </mat-grid-tile>
            <mat-grid-tile [style.height.px]="50" colspan="2">
                <div class="flex-col-start">
                    <span class="swiper-header">{{selMenu.nameSQ}}</span>
                    <span class="text-sm-grey">{{selMenu.sizeName}}</span>
                    <span class="text-sm-orange" (click)="openEditOrder(selMenu)">{{'ORDER_CART.MODIFY_ORDER' | translate}}</span> 
                </div>
            </mat-grid-tile>
            <mat-grid-tile [style.height.px]="50" colspan="1">
                <div class="flex-col-end">
                    <div class="close-icon" (click)="openConfirmDialog({ product: selMenu, index: i })">
                        <img height="12" width="12" src="../../assets/icons/x.svg"/>
                    </div>
                    <div class="btn-group">
                        <button mat-flat-button class="btn-subtract" (click)="decreaseQuantity(selMenu)">-</button>
                        <div class="item-quantity">{{selMenu.quantity}}</div>
                        <button mat-flat-button class="btn-add" (click)="increaseQuantity(selMenu)">+</button>
                      </div>
                      <div *ngIf="selMenu.options?.length">
                        <ng-container *ngFor="let option of selMenu.options">
                          <ng-container *ngIf="option.id === selMenu.productDetailID">
                            <span class="price-orange">
                              {{ option.sellingPriceWithVat | roundDecimal }} {{'CLIENT.CURRENCY' | translate}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>
                </div>
            </mat-grid-tile>
            <hr class="line-orange"/>      
        </mat-grid-list>
    </div>
    <div>
        <div class="buttons-block-100">
            <button mat-stroked-button  (click)="changeDeliveryPickup(true)"
                [ngClass]="deliveryPickup === true ? 'btn-select-delivery' : 'btn-delivery'">
                {{'ORDER_CART.SEND' | translate}}
            </button>
            <button mat-flat-button (click)="changeDeliveryPickup(false)"
                [ngClass]="deliveryPickup === false ? 'btn-select-pickup' : 'btn-pickup'">
                {{'ORDER_CART.GET' | translate}}
            </button>
        </div>
        
        <div class="flex-space-m">
            <span class="orange-color">{{'ORDER_CART.DELIVERY' | translate}}:</span>
            <span class="orange-color">0 {{'CLIENT.CURRENCY' | translate}}</span>
        </div>
        <div class="flex-space-margin">
            <span class="orange-color">{{'ORDER_CART.SUBTOTAL' | translate}}:</span>
            <span class="orange-color">{{total() | roundDecimal}} {{'CLIENT.CURRENCY' | translate}}</span>
        </div>

        <div class="cart-bg">
            <span class="grey-color">{{'CLIENT.TOTAL' | translate}}</span>
            <span class="price-bold">{{total() | roundDecimal}} {{'CLIENT.CURRENCY' | translate}}</span>
        </div>
        <div class="btn-shop-order">
            <button mat-stroked-button class="btn-shop" (click)="navigateBack()">              
                {{'ORDER_CART.BACK_TO_MENU' | translate}}
            </button>
            <button mat-flat-button class="btn-order" (click)="openPayment()">
                {{'ORDER_CART.CONFIRM_ORDER' | translate}}
            </button>
        </div>
    </div>
</div>