import { Component, OnInit } from '@angular/core';
import { UserService } from '../Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
const PASSWORD_MIN_LENGTH = 8;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  changePassword = new FormGroup({
    NewPassword : new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(PASSWORD_MIN_LENGTH),
      this.patternValidator(new RegExp("(?=.*[0-9])"), {
        requiresDigit: true
      }),
      this.patternValidator(new RegExp("(?=.*[A-Z])"), {
        requiresUppercase: true
      }),
      this.patternValidator(new RegExp("(?=.*[a-z])"), {
        requiresLowercase: true
      }),
      this.patternValidator(new RegExp("(?=.*[$#@^!%*?&])"), {
        requiresSpecialChars: true
      })
    ]),
    ),
    ConfirmNewPassword: new FormControl(''),
    token: new FormControl(''),
    userId: new FormControl(''),
  });
  Token: string;
  viewPassword: boolean = false;
  labelError:string='';
  labelInfo:string='';
  isFormSubmitted: boolean = false;


  constructor(
    private userService: UserService,
    private translate: TranslateService,
    protected activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getParams();
  }
  changepassword(data) {
    this.changePassword.controls['token'].setValue(localStorage.getItem('token'))
    this.changePassword.controls['userId'].setValue(localStorage.getItem('userId'))
    this.isFormSubmitted = true;
    if(this.changePassword.valid && (this.changePassword.controls['NewPassword'].value === this.changePassword.controls['ConfirmNewPassword'].value))
    {
      this.userService.changepassword(data).subscribe({
        next:(res:any) => {
          if (res.statusCode === 401 && res.message === "Token not verified")
          {
            this.labelInfo='';
            this.labelError = 'CHANGE_PASSWORD.PASSWORD_NOT_VALID';
          }
          else{
            this.labelError='';
            this.labelInfo='CHANGE_PASSWORD.PASSWORD_VALID';
          }
      },
        error:(error:any) =>{
          this.labelInfo='';
          this.labelError = 'CHANGE_PASSWORD.PASSWORD_NOT_VALID';
      }
    });
    }
  }

  getParams() {
    this.activatedRoute.queryParamMap.subscribe((param: any) => {
      let userId;
      this.changePassword.controls['token'].setValue(param.params.token);
      this.changePassword.controls['userId'].setValue(param.params.userId);
      return userId;
    });
  }

  passwordShowHide(){
    this.viewPassword = !this.viewPassword;
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if the control value is empty return no error.
        return null;
      }

      // test the value of the control against the regexp supplied.
      const valid = regex.test(control.value);

      // if true, return no error, otherwise return the error object passed in the second parameter.
      return valid ? null : error;
    };
  }
}
