import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AdminComponentComponent } from "../admin-component/admin-component.component"

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<AdminComponentComponent>{
  canDeactivate(component: AdminComponentComponent): boolean {

    if(!component.canDeactivate()){
      if(confirm("You sure?")){
        return true;
      } else return false;
    }
    return true;
  }
}
