<div class="header" fxLayout="row" fxLayoutWrap>
    <div class="language-select">
      <mat-form-field class="language-input">
        <mat-select [ngModel]="selectedLanguage" panelClass="language-panel" (selectionChange)="changeLanguage($event)">
          <mat-option value="al">AL</mat-option>
          <mat-option value="mk">MK</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="!isLogged" class="user-login-button-container d-flex">
        <button *ngIf="isComingFrom()" class="user-signup-button" color="primary" (click)="navigateBack()">              
          {{'MENU.MENU' | translate}}
        </button>
        <button class="user-login-button" color="primary" routerLink="/signin">{{'HEADER.LOG_IN' | translate}}</button>
        <button class="user-signup-button" color="primary" routerLink="/signup">{{'HEADER.REGISTER' | translate}}</button>
      </div>
      <div *ngIf="isLogged" class="w-100">
          <i class="material-icons icons" routerLink="/menu">menu</i>
      </div>
    </div>
  </div>
  