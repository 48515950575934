<div class="mdc-layout-grid ">
  <div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-12-tablet ">
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone text-center">
      <div>
        <div class="Logo">
          <img class="companyLogo" src="{{imgLogo}}" alt="companyLogo">
        </div>
      </div>
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone ">
      <div class="buttonContainer">
        <a href="/previewMenu" target="_blank" class="previewButton">
          <button mat-button class="txt-btn" (click)="previewMenu()">
            {{ "ADMIN.PREVIEW" | translate }}
          </button>
        </a>
        <button class="publishButton" mat-button (click)="onSaveMenu()">
          <img src="../../assets/icons/upload-logo.svg" alt="upload Logo" class="publishLogo">
          {{"ADMIN.PUBLISH" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="menu">
  <h1>
    {{ "ADMIN.ACTIVE-CATEGORIES" | translate }}
  </h1>

  <!--Fill accordion with menu data-->
  <cdk-accordion #categoryExpansionPanel="cdkAccordion" cdkDropList id="menuList" #Categories="cdkDropList"
    [cdkDropListData]="categories" [cdkDropListConnectedTo]="[removedCategories]" (cdkDropListDropped)="drop($event)">
    <mat-expansion-panel cdkDrag (cdkDragStarted)="drag($event, panel)"
      *ngFor="let category of categories; let i = index;" #panel hideToggle class="mat-expansion">
      <!--Show only categories with hideOnMenu === false-->
      <mat-expansion-panel-header *ngIf="category.hideOnMenuCategory === false" class="accordion-header">
        <mat-panel-title class="mat-title">
          <mat-icon *ngIf="!panel.expanded" class="down-icon"><img src="../../assets/icons/dark-dots.svg"
              alt="three-dots"></mat-icon>
          <mat-icon *ngIf="panel.expanded"><img src="../../assets/icons/group-dots.svg" alt="three-dots"></mat-icon>
          <mat-icon *ngIf="!panel.expanded"><img src="../../assets/icons/right-arrow.svg"></mat-icon>
          <mat-icon *ngIf="panel.expanded" class="up-icon"><img src="../../assets/icons/arrow-up.svg"></mat-icon>
          <div class="categoryName"
            [ngStyle]="{'color': panel.expanded? '#DAD8C6' : '#706765', 'padding-bottom': panel.expanded? '9px' : '1px'}">
            {{ category[name] |
            uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div cdkDropList id="menuProductsList" #Products="cdkDropList" [cdkDropListData]="category.products"
        (cdkDropListDropped)="drop($event,i)">
        <section class="example-section">
          <div class="mdc-layout-grid">
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
                *ngFor="let product of category.products, let j = index">
                <div cdkDrag>
                  <div class="icon">
                    <div class="d-f-gap">
                      <div class="image-dots">
                        <img class="dots" src="../../assets/icons/dark-dots.svg" alt="three-dots">
                      </div>
                      <div class="check-box">
                        <mat-checkbox class="checkbox" *ngIf="product" #id [(ngModel)]="!product.hideOnMenu" #product.id
                          (change)="setActive($event, i, j)">
                        </mat-checkbox>
                      </div>
                      <div class="product-name">
                        <div class="productName">{{product[name]}}</div>
                      </div>
                    </div>
                    <div class="d-f-gap width-d-f-g">
                      <div class="hrz-line">
                        <hr class="hr-line">
                      </div>
                      <div class="price">
                        <div class="productPrice">{{product.price}}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="notes">{{product.notes}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </mat-expansion-panel>
    <div class="example" cdkDrag></div>
  </cdk-accordion>
  <!-- </div> -->

  <!-- <div class="menu"> -->
  <h1>
    {{ "ADMIN.INACTIVE-CATEGORIES" | translate }}
  </h1>
  <cdk-accordion cdkDropList id="removedCategories" cdkDropListSortingDisabled #removedCategories="cdkDropList"
    [cdkDropListData]="categories" [cdkDropListConnectedTo]="[Categories]" (cdkDropListDropped)="drop($event)">
    <mat-expansion-panel *ngFor="let Category of categories" cdkDrag hideToggle class="mat-expansion" #panel disabled>
      <mat-expansion-panel-header *ngIf="Category.hideOnMenuCategory === true" class="accordion-header">
        <mat-panel-title class="mat-title">
          <mat-icon><img src="../../assets/icons/dark-dots.svg" alt="three-dots" class="dot"></mat-icon>
          <mat-icon><img src="../../assets/icons/right-arrow.svg"></mat-icon>
          <div class="inactiveCategoryName">
            {{ Category.nameSQ | uppercase}}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <!-- <div class="example" cdkDrag></div> -->
  </cdk-accordion>
</div>
