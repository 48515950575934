

<app-header [showSearch]="false"></app-header>

<div class="user-page-container">
  <div class="user-page">
    <form class="user-form" [formGroup]="signInForm">
    <mat-form-field appearance="fill" class="user-form-field">
      <mat-label>{{'SIGN_IN.EMAIL' | translate}}</mat-label>
      <input matInput id="email" placeholder="{{'SIGN_IN.EMAIL' | translate}}" value="" formControlName="Username">
    </mat-form-field>

    <mat-form-field appearance="fill" class="user-form-field">
      <mat-label>{{'SIGN_IN.PASSWORD' | translate}}</mat-label>
      <div class="input-container">
        <input matInput id="password" [type]="viewPassword ? 'text' : 'password'" placeholder="{{ 'CHANGE_PASSWORD.NEW_PASSWORD' | translate }}" formControlName="Password" />
        <div class="eye-icon-container">
          <img class="eye-icon" src="../../assets/icons/eye.ico" height="25" *ngIf="!viewPassword" (click)="passwordShowHide()" />
          <img class="eye-icon" src="../../assets/icons/eye-off.ico" height="25" *ngIf="viewPassword" (click)="passwordShowHide()" />
        </div>
      </div>
    </mat-form-field>
    
    <div [ngClass]="labelError !== ''? 'visible':'invisible'" class="text-danger">
      {{labelError| translate}}
    </div>
  </form>
  <br>
  <button mat-raised-button class="user-login-btn" appearance="fill" (click)="login(signInForm.value)">{{'SIGN_IN.LOG_IN' | translate}}</button>
  <br>
  
  <a [routerLink]="['/forgot-password']">{{'SIGN_IN.FORGOT_PASSWORD' | translate}}</a>
  <br>
  
  <a [routerLink]="['/signup']" >{{'SIGN_IN.DONT_HAVE_AN_ACCOUNT' | translate}}</a>
  
  </div>
</div>