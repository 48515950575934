import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private translate: TranslateService) {}
  title = 'restaurant-app';
  ngOnInit(): void {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const defaultLanguage = storedLanguage || 'al'; // Set your default language here

    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
  }

}
