import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-confirmation-success',
  templateUrl: './email-confirmation-success.component.html',
  styleUrls: ['./email-confirmation-success.component.css']
})
export class EmailConfirmationSuccessComponent {
  constructor(
    private router: Router,
  ) {

  }
  goToLogin(){
    this.router.navigate(['/signin']);
  }
}
