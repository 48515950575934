<app-header></app-header>
<div class="div-containers">
  <div class="swipper-container">
    <div class="logo-bg">
      <img height="70" width="70" [src]="img_path + companyLogo" (error)="onImageError($event)"/>
    </div>
    <div class="search-bg">
      <img src="../../assets/icons/search.svg"/>
      <input type="search" [(ngModel)]="searchValue"/>
    </div>
    <div class="btn-categories-container">
      <button mat-flat-button [ngClass]="categorySelected == 'all' ? 'btn-categories-selected' : 'btn-categories'" (click)="allCategories()">{{'CLIENT.ALL_CATEGORIES' | translate}}
      </button>
      <button mat-flat-button *ngFor="let category of categories" (click)="filterByCategory(category[name], category['id'])"
              [ngClass]="category[name] == categorySelected ? 'btn-categories-selected' : 'btn-categories'">
        <span>{{category[name]}}</span>
      </button>
    </div>
    <div>
      <swiper #swiper [slidesPerView]="1" [spaceBetween]="10" [navigation]="true" [pagination]="{ clickable: true}" (slideChange)="onSlideChange($event)">
        <ng-template swiperSlide *ngFor="let product of (filteredProducts | search:searchValue); let i = index">
              <div class="picsum-img-wrapper">
                  <img [src]="img_path + product.image" alt="Menu Product" />
                  <div class="swiper-bg">
                      <mat-list>
                          <mat-list-item>
                            <span matListItemTitle class="swiper-header">{{product?.[this.name]}}</span>
                          </mat-list-item>
                          <mat-list-item>
                            <div class="description-container">
                              <span matListItemTitle class="description-title">{{'CLIENT.DESCRIPTION' | translate}}</span>
                              <div class="btn-group">
                                <button mat-flat-button class="btn-subtract" (click)="removeQuantity(product)">-</button>
                                <div class="item-quantity">{{product?.quantity}}</div>
                                <button mat-flat-button class="btn-add" (click)="addQuantity(product)">+</button>
                              </div>
                            </div>
                          </mat-list-item>
                          <mat-list-item>
                            <span matListItemLine class="description-text">{{product?.notes}}</span>
                          </mat-list-item>
                          <mat-list-item>
                            <mat-radio-group aria-label="Select an option">
                              <div class="card-container" [ngClass]="{'radio-overflow': product.options.length > 3}">
                                <div class="offer-bg" [ngClass]="{'card-space': product.options.length - 1 != i}" *ngFor="let details of product.options; let i = index" (click)="selectedPrice(details)">
                                  <mat-radio-button class="radio-btn" [value]="details?.sellingPriceWithVat" [checked]="i === 0">
                                    </mat-radio-button>
                                    <span class="grey-color-sm">{{details?.productName}}</span>
                                    <span class="price-bold-md">{{details?.sellingPriceWithVat | roundDecimal}} {{'CLIENT.CURRENCY' | translate}}</span>
                                  </div>
                                </div>
                              </mat-radio-group>
                          </mat-list-item>
                      </mat-list>
                  </div>
              </div>
              <div class="swiper-buttons">
                  <button mat-raised-button class="btn-add-cart" (click)="addToCart(product)">
                    {{'CLIENT.ADD_TO_CART' | translate}}
                  </button>
              </div>            
          </ng-template>
      </swiper>
    </div>
  </div>
  <div class="cart-bg-client">
    <span class="grey-color">{{'CLIENT.TOTAL' | translate}}</span>
    <span class="price-bold">{{totalPrice | roundDecimal}} {{'CLIENT.CURRENCY' | translate}}</span>
    <mat-icon matBadge="{{cartProductsNr}}" matBadgeHidden="{{cartProductsNr == 0}}" (click)="goToCart()"
    class="custom-badge"><img class="img-position" src="../../assets/icons/cart.svg"/></mat-icon>
  </div>
</div>