import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RouteEnum } from 'src/app/constants/route.enum';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../Services/auth.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements HttpInterceptor, CanActivate {
  constructor(protected router: Router, public authService: AuthService) {}

  // Retrieve token from localStorage
  getToken() {
    return localStorage.getItem('companyToken') || '';
  }

  // Intercept HTTP requests to add token to headers
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedUrls = ['/MenuApi/GetAllCompanies', '/MenuApi/GetAllCompaniesByCategory', '/MenuApi/GetNewCompanyId', RouteEnum.clientConomeApi];
    const isExcluded = excludedUrls.some((url) => req.url.includes(url));

    if (isExcluded) {
      return next.handle(req);
    }

    const authRequest = req.clone({
      headers: req.headers.set('Authorization', this.getToken()),
    });

    return next.handle(authRequest).pipe(
      catchError((err, caught) => {
        console.log(err);
        if (err.status === 403) {
          this.router.navigate(['/login']); // Optional: redirect on 403
          return of(err);
        }
        throw err;
      })
    );
  }

  // Check if the user can activate the route (Auth Guard)
  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const token = this.getToken();
      if (token) {
        resolve(true);
      } else {
        this.router.navigate(['/login']); // Redirect if not authenticated
        resolve(false);
      }
    });
  }
}
