import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  product;
  index;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogComponent) {
    this.product = data.product;
    this.index = data.index;
  }

  onConfirm(): void {
    this.dialogRef.close(this.index);
  }

  onDismiss(): void{
    this.dialogRef.close(false);
  }
}
